import '../../css/build.scss';

import Cookies from 'js-cookie';
import Highcharts from 'highcharts';
import accessibilityInit from 'highcharts/modules/accessibility';
import Dimensions from 'highcharts/highcharts-3d';

import 'bootstrap-toggle';
import '@atomaras/bootstrap-multiselect';
import 'jquery-number';

import 'jquery-ui/ui/effects/effect-highlight';
import 'chosen-js';

import 'datatables.net-bs';
import 'datatables.net-buttons-bs';
import 'datatables.net-colreorder-bs';
import 'datatables.net-fixedcolumns-bs';
import 'datatables.net-fixedheader-bs';
import 'datatables.net-responsive-bs';
import 'datatables.net-rowreorder-bs';
import 'datatables.net-select-bs';

import { ajaxPromise, checkChosenChanged, confirmDialog, displayNotification, dtColumnIndicies, empty, fileInput, filesizePretty, getDateShort, getDtRowData, getMonthNameShort, highlight, htmlEsc, logerror, logme, post, setCollapse, setTimeoutPromise, toggleSlide, triggerSubmit, triggerWindowResize, validEmail, zxcvbnProgressBar } from './utils';
import { ASSESSMENT_CONCHECKLIST, ASSESSMENT_DILIGENCE, ASSESSMENT_INHERENT, ASSESSMENT_OFFBOARDING, ASSESSMENT_ONBOARDING, ASSESSMENT_REVIEW, ASSESSMENT_VENDORVALUE, MFA_METHOD_APP, MFA_METHOD_EMAIL, MFA_METHOD_SMS, NOTIFY_AUTO, NOTIFY_MANUAL, NOTIFY_MANUAL_AND_AUTO, TASK_NOTIFY_MYSELF, TASK_SCHEDULED } from './constants';

Dimensions(Highcharts);
accessibilityInit(Highcharts);

$.fn.dataTable.defaults.ajax = {type: 'POST'};
$.fn.dataTable.defaults.autoWidth = true;
$.fn.dataTable.defaults.buttons = [];
$.fn.dataTable.defaults.dom = `
	<'row'<'col-sm-6'l><'col-sm-6 datatable-searchbar-and-buttons'fB>>
	<'row'<'col-sm-12'tr>>
	<'row'<'col-sm-5'i><'col-sm-7'p>>
`;
$.fn.dataTable.defaults.language = {processing: 'Processing...'};
$.fn.dataTable.defaults.processing = true;
$.fn.dataTable.defaults.responsive = true;
$.fn.dataTable.defaults.serverSide = true;

$('#buildnumber').text(buildnumber);

const globalCharts = {
	inherent: null,
	residual: null,
	sla: null,
	kpi: null,
	critical: null,
	vendValue: null,
	performanceSla: null,
	performanceKpi: null,
};

var idleTimer = null;
var idleLogout = null;
var activity = 0;
const idleWait = 900000;
const idleWarning = 180000;

function user_is_active() {
	activity = 0;
	clearTimeout(idleTimer);
	idleTimer = setTimeout(function () {
		// Idle Event

		$('#inactive_dialog')
			.find('.modal-body')
			.html(<p>You've been idle for {(idleWait - idleWarning) / 60000} minutes.<br />Please confirm you are still active, otherwise you will be signed out in {idleWarning / 60000} minutes.</p>);
		$('#inactive_dialog_action').off('click');
		$('#inactive_dialog_action').on('click', function () {
			clearTimeout(idleLogout);
			$('#inactive_dialog').modal('hide');
		});
		$('#inactive_dialog').modal({
			backdrop: 'static',
			keyboard: false,
		}).trigger('show.bs.modal');

		idleLogout = setTimeout(function () {
			localStorage.setItem('logout-event', 'logout' + Math.random()); // trigger logout for all other tabs
			window.location.href = '/logout';
		}, idleWarning);
	}, idleWait - idleWarning);
}

export const loadTaskDescs = async () => {
	if (!$('#vendor_tasks_html').length) return;
	const jsData = JSON.parse(atob($('#vendor_form_container').data('js')));
	if (!jsData.id) return;
	const user_is_external = !!jsData.isExternal;
	const postData = {
		vend_id: jsData.id,
		all_tasks: null,
	};
	if (!user_is_external) postData.all_tasks = $('#vendor_tasks_all').is(':checked') ? 1 : 0;

	try {
		const res = await ajaxPromise('/data/load_task_descs', {data: postData});
		if (res.rc !== 'OK') throw res;

		let tasks_html = res.html_descs.join('<hr>');

		$('#vendor_tasks_list').html(tasks_html);
		$('#vendor_tasks_html').show();
	} catch (error) {
		$('#vendor_tasks_list').html('No tasks found.');
		$('#vendor_tasks_html').show();
	}
}

export const loadConcentrationMap = async ({opscenters, isVendorEdit = false}: {opscenters: any, isVendorEdit: boolean}) => {
	if (!opscenters.length) {
		$('#concentration_none').show();
		$('#concentration_cont').hide();
		return;
	}

	// Request needed libraries.
	const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
	const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
	const { spherical } = await google.maps.importLibrary('geometry') as google.maps.GeometryLibrary;
	const { MarkerClusterer } = await import('@googlemaps/markerclusterer');

	const markers: {
		position: google.maps.LatLngLiteral,
		title: string,
		vendor: string,
		ref?: google.maps.marker.AdvancedMarkerElement,
		infoWindow?: google.maps.InfoWindow,
		on: boolean,
	}[] = opscenters.map((ops) => ({
		position: {
			lat: +ops.opscenter_lat,
			lng: +ops.opscenter_lng,
		},
		title: ops.opscenter_name,
		vendor: ops.vend_name,
		on: true,
	}));

	if (!markers.length) {
		$('#concentration_none').show();
		$('#concentration_cont').hide();
		return;
	}

	const mapBounds = new google.maps.LatLngBounds();
	markers.forEach(({position}) => mapBounds.extend(position));

	const map = new Map(
		document.getElementById('concentration_map') as HTMLElement,
		{
			mapId: 'CONCENTRATION_RISK_MAP',
		},
	);
	map.fitBounds(mapBounds);

	const infoWindow = new google.maps.InfoWindow({
		content: '',
		disableAutoPan: true,
	});

	markers.forEach((marker) => {
		const ref = new AdvancedMarkerElement({
			map,
			title: marker.title,
			position: marker.position,
		});
		const samePositionMarkers = markers.filter((m) => m.position.lat === marker.position.lat && m.position.lng === marker.position.lng);
		const opsCenterTitles = samePositionMarkers.map((m) => isVendorEdit ? m.title : `<b>${m.vendor}:</b> ${m.title}`).join('<br>');
		marker.infoWindow = new google.maps.InfoWindow({
			content: `<div style="color: black;">${opsCenterTitles}</div>`,
			disableAutoPan: true,
		});
		ref.addListener('click', () => {
			infoWindow.setContent(`<div style="color: black;">${opsCenterTitles}</div>`);
			infoWindow.open(map, ref);
		});
		marker.ref = ref;
	});

	const cluster = new MarkerClusterer({map});

	const filterMarkers = () => {
		const milesApart = +$('#concentration_distance_apart').val();
		const metersApart = milesApart * 1609.34;
		const mapBounds = new google.maps.LatLngBounds();
		markers.forEach((marker, index) => {
			const closeMarker = metersApart && markers.find((otherMarker, otherIndex) => otherIndex !== index && spherical.computeDistanceBetween(marker.position, otherMarker.position) <= metersApart);

			if (metersApart && !closeMarker) {
				marker.on = false;
				return;
			}

			marker.on = true;
			mapBounds.extend(marker.position);
		});

		toggleMarkers();
		if (!mapBounds.isEmpty()) map.fitBounds(mapBounds);
	};

	const toggleMarkers = () => {
		markers.forEach((marker) => {
			marker.ref.map = null;
			cluster.removeMarker(marker.ref);
			if (marker.on) {
				if ($('#concentration_cluster').is(':checked')) cluster.addMarker(marker.ref);
				else marker.ref.map = map;
			}
		});
	};

	$('#concentration_distance_apart').off('change').on('change', () => filterMarkers());
	$('#concentration_cluster').off('change').on('change', () => toggleMarkers());
	filterMarkers();

	$('#concentration_none').hide();
	$('#concentration_cont').show();
};

let libraryDt: DataTables.Api = null;

export const taskForm = ({$cont, $submit, recipientView = false, task = null, onCreate = () => {}}) => {
	const currentDate = new Date().toISOString().split('T')[0];

	$cont.find('#task_add_vendor').chosen({width: '100%'});

	$cont.find('#task_add_senddate, #task_add_duedate, #task_add_recurring, #task_add_enddate').off('change');
	$cont.find('#task_add_recurring').on('change', () => {
		if (+$cont.find('#task_add_recurring').val()) {
			$cont.find('#task_add_enddate_group').show();
		}
		else {
			$cont.find('#task_add_enddate_group').hide();
		}
	});

	const clearCreateTask = () => {
		$cont.find('#task_add_form').trigger('reset');
		$cont.find('#task_add_recur_num_group').hide();
		$cont.find('#task_add_form').off('submit');
		$cont.find('#task_add_notify').val([TASK_NOTIFY_MYSELF]).trigger('chosen:updated');
		$cont.find('#task_add_notify_updated').prop('checked', false);
		$cont.find('#task_add_recurring').prop('disabled', false).trigger('change');
		$cont.find('#task_add_vendor').trigger('chosen:updated');
		$cont.find('#task_add_enddate').prop('disabled', false);
	};

	const clearAddAttachment = () => {
		$('#task_attachment_form').trigger('reset');
		$('#task_attachment_file_label').text('File');
		$('#task_attachment_file').trigger('change');
	};

	const selectedLibs = () => libraryDt.rows({ selected: true }).data().toArray();

	const initLibraryDatatable = () => {
		libraryDt = $('#task_attachment_library_table').DataTable({
			ajax: {
				url: '/data/task_attach_library_load',
			},
			buttons: [
				{
					text: 'Use',
					className: 'use ml-2 btn btn-sm btn-primary',
				},
			],
			columns: [
				{
					data: null,
					defaultContent: '',
					orderable: false,
					searchable: false,
					className: 'select-checkbox',
					render: null,
				},
				{
					title: 'Title',
					data: 'lib_title',
				},
				{
					title: 'Type',
					data: 'lib_type_pretty',
					searchable: false,
				},
				{
					title: 'Description',
					data: 'lib_desc',
				},
				{
					title: 'Actions',
					data: 'buttons',
					className: 'text-right',
					orderable: false,
					searchable: false,
				},
			],
			columnDefs: [
				{ targets: [-1], responsivePriority: 1, render: null },
				{ targets: '_all', render: (val) => htmlEsc(val) },
			],
			order: [[1, 'asc']],
			select: {
				style: 'os',
				selector: 'td:first-child',
			},
		});

		libraryDt.on('select', () => {
			if (selectedLibs().length) libraryDt.buttons(['.use']).enable();
		});

		libraryDt.on('deselect', () => {
			if (!selectedLibs().length) libraryDt.buttons(['.use']).disable();
		});
	};

	clearCreateTask();

	let attachments = [];

	const editAttachment = (attach = null) => {
		clearAddAttachment();
		if (!libraryDt) initLibraryDatatable();

		let $startingTab = $('#task_attachment_tab_upload_trigger');
		if (attach) {
			$('#task_attachment_title').val(attach.title);
			$('#task_attachment_desc').val(attach.desc);
			$('#task_attachment_recur').prop('checked', +attach.recur);
			$('#task_attachment_file_label').text('Replace File');
			if (attach.lib_id) $startingTab = $('#task_attachment_tab_resource_trigger');
		}
		libraryDt.off('draw').on('draw', () => {
			$('#task_attachment_library_table').find('.select-all').removeClass('selected').off('click').on('click', ({target}) => {
				$(target).toggleClass('selected');
				if ($(target).hasClass('selected')) libraryDt.rows().select();
				else libraryDt.rows().deselect();
			});
			libraryDt.buttons(['.use']).disable();
			if (attach && attach.lib_id) {
				libraryDt.rows().every(function () {
					const row = this;
					const data = row.data();
					if (data.lib_id == attach.lib_id) row.select();
				});
			}
		});
		$startingTab.tab('show');
		libraryDt.draw();

		libraryDt.button('.use').node().off('click').on('click', () => {
			selectedLibs().forEach((lib) => {
				const isSelectedAttach = (attach && attach.lib_id == lib.lib_id);
				const newAttachment = {
					hash: isSelectedAttach ? attach.hash : null,
					lib_id: lib.lib_id,
					title: lib.lib_title,
					desc: lib.lib_desc,
					file: null,
					recur: $('#task_attachment_recur').is(':checked') ? 1 : 0,
				};
				if (isSelectedAttach) {
					const index = attachments.findIndex((a) => a.hash === attach.hash);
					attachments[index] = newAttachment;
				} else {
					attachments.push(newAttachment);
				}
			});
			refreshAttachments();

			$('#task_attachment_modal').modal('hide');
		});

		$('#task_attachment_library_table').off('click').on('click', '.use', ({target}) => {
			const row = getDtRowData(libraryDt, target);
			const newAttachment = {
				hash: attach ? attach.hash : null,
				lib_id: row.lib_id,
				title: row.lib_title,
				desc: row.lib_desc,
				file: null,
				recur: $('#task_attachment_recur').is(':checked') ? 1 : 0,
			};
			if (attach) {
				const index = attachments.findIndex((a) => a.hash === attach.hash);
				attachments[index] = newAttachment;
			} else {
				attachments.push(newAttachment);
			}
			refreshAttachments();

			$('#task_attachment_modal').modal('hide');
		});

		$('#task_attachment_add').off('click').on('click', () => {
			triggerSubmit($('#task_attachment_form'));
		});
		$('#task_attachment_form').off('submit').on('submit', (event) => {
			event.preventDefault();

			const files = $('#task_attachment_file').prop('files');
			if (!attach && !files.length) {
				displayNotification('Task', 'Please select a file.', 'warning');
				return;
			}

			const newAttachment = {
				hash: attach ? attach.hash : null,
				lib_id: null,
				title: $('#task_attachment_title').val().toString().trim(),
				desc: $('#task_attachment_desc').val().toString().trim(),
				file: attach ? (files.length ? files[0] : attach.file) : files[0],
				recur: $('#task_attachment_recur').is(':checked') ? 1 : 0,
			};
			if (attach) {
				const index = attachments.findIndex((a) => a.hash === attach.hash);
				attachments[index] = newAttachment;
			} else {
				attachments.push(newAttachment);
			}
			refreshAttachments();

			$('#task_attachment_modal').modal('hide');
		});
		$('#task_attachment_modal').modal('show');
	};

	$cont.find('#task_add_attachments')
	.off('click')
	.on('click', '.delete', ({target}) => {
		const index = $(target).closest('li').index();
		attachments.splice(index, 1);
		refreshAttachments();
	})
	.on('click', '.edit', ({target}) => {
		editAttachment(attachments[$(target).closest('li').index()]);
	});

	$cont.find('#task_add_attachments_add').hide().off('click');
	if (!recipientView) $cont.find('#task_add_attachments_add').show().on('click', () => editAttachment());

	const refreshAttachments = () => {
		const $attachments = $(
			<ul className="list-group">
				{attachments.map((attach) => (
					<li className="list-group-item">
						<span className="d-flex justify-content-between">
							<span>{attach.title}{attach.lib_id ? (<span className="badge ms-2">Resource Library</span>) : ''}</span>
							<span>
								<button type="button" className="edit btn btn-sm btn-primary me-2"><i className="fa fa-edit"></i></button>
								<button type="button" className="delete btn btn-sm btn-danger"><i className="fa fa-close"></i></button>
							</span>
						</span>
					</li>
				))}
			</ul>
		);
		$cont.find('#task_add_attachments').empty().append($attachments);
	};
	refreshAttachments();

	const refreshRecurDates = async () => {
		const postData = {
			senddate: $cont.find('#task_add_senddate').val() || null,
			duedate: $cont.find('#task_add_duedate').val(),
			recurring: +$cont.find('#task_add_recurring').val(),
			enddate: $cont.find('#task_add_enddate').val(),
		};
		if (!postData.duedate || !postData.recurring || !postData.enddate) {
			$cont.find('#task_add_recur_dates').hide().empty();
			return;
		};

		let dates = [];
		try {
			const res = await ajaxPromise('/data/task_recur_dates', {data: JSON.stringify(postData)});
			if (res.rc !== 'OK') throw res;
			dates = res.dates;
		} catch (error) {
			logerror('task_recur_dates', error);
			return;
		}

		$cont.find('#task_add_recur_dates').show().empty();

		const displayDates = JSON.parse(JSON.stringify(dates));
		if (dates.length > 5) {
			displayDates.splice(3, dates.length - 4, null);
		}
		$cont.find('#task_add_recur_dates').append(
			<>
				<div><b>Future Tasks</b></div>
				<ul className="list-group">
					{displayDates.map((info) => info ? (
						<li className="list-group-item"><b>Send Date: </b><div style="display: inline-block; width: 100px;">{getDateShort(info.senddate)}</div><b>Due Date: </b> {getDateShort(info.duedate)}</li>
					) : (
						<li className="list-group-item">...{dates.length - 4} more...</li>
					))}
				</ul>
			</>
		);
	};
	$cont.find('#task_add_senddate, #task_add_duedate, #task_add_recurring, #task_add_enddate').on('change', () => refreshRecurDates());

	if (task && task.tk_sentdate) {
		$cont.find('#task_add_senddate').prop('disabled', true).prop('min', null);
	} else {
		const date = (new Date()).toISOString().substring(0, 10);
		const minSenddate = task ? (task && task.tk_senddate < date ? task.tk_senddate : date) : date;
		$cont.find('#task_add_senddate').prop('min', minSenddate);
	}

	if (task && +task.tk_recurring && !+task.tk_latest && task.tk_status != TASK_SCHEDULED) {
		$cont.find('#task_add_recurring').prop('disabled', true);
		$cont.find('#task_add_enddate').prop('disabled', true);
	}

	if (!task || (task && task.tk_status == TASK_SCHEDULED)) $cont.find('#task_add_notify_updated_cont').hide();
	else $cont.find('#task_add_notify_updated_cont').show();

	if (task) {
		$cont.find('#task_add_vendor').val(task.vend_ids).trigger('chosen:updated');
		$cont.find('#task_add_duedate').prop('min', task.tk_duedate > currentDate ? currentDate : task.tk_duedate);
		$cont.find('#task_add_priority').val(task.tk_priorid);
		$cont.find('#task_add_type').val(task.tk_ttypeid);
		$cont.find('#task_add_recurring').val(task.tk_recurring).trigger('change');
		$cont.find('#task_add_duedate').val(task.tk_duedate);
		$cont.find('#task_add_senddate').val(task.tk_senddate || '');
		$cont.find('#task_add_enddate').val(task.tk_enddate || '');
		$cont.find('#task_add_recips').val(task.recipient_emails);
		$cont.find('#task_add_notify').val(task.tk_notify);
		$cont.find('#task_add_notes').val(task.tk_notes);
		task.attachments.forEach((attach) => {
			attachments.push({
				hash: attach.hash,
				lib_id: attach.lib_id,
				title: attach.title,
				desc: attach.desc,
				file: null,
				recur: attach.recur,
			});
		});
		refreshAttachments();
	}

	$cont.find('#task_add_form').on('submit', (event) => {
		event.preventDefault();

		const postData = {
			tk_guid: task ? task.tk_guid : null,
			vend_ids: $cont.find('#task_add_vendor').val(),
			priority: +$cont.find('#task_add_priority').val(),
			type: +$cont.find('#task_add_type').val(),
			recurring: +$cont.find('#task_add_recurring').val(),
			enddate: $cont.find('#task_add_enddate').val(),
			senddate: $cont.find('#task_add_senddate').val(),
			duedate: $cont.find('#task_add_duedate').val(),
			recipients: $cont.find('#task_add_recips').val().toString().trim(),
			notify: $cont.find('#task_add_notify').val().map((val) => +val),
			notes: $cont.find('#task_add_notes').val(),
			attachments,
			notify_updated: task ? ($cont.find('#task_add_notify_updated').is(':checked') ? 1 : 0) : 0,
		};

		if (!postData.duedate) {
			displayNotification('Task', 'Please enter a Due Date.', 'warning');
			return;
		}

		if (postData.senddate && postData.senddate > postData.duedate) {
			displayNotification('Task', 'Send Date must be before Due Date.', 'warning');
			return;
		}

		const save = async (options = {update_repeating: 0}) => {
			$submit.prop('disabled', true);

			let success = false;

			try {
				const formData = new FormData();
				formData.append('type', 'task_create');
				formData.append('json', '1');
				formData.append('data', JSON.stringify({...postData, ...options}));
				attachments.forEach((attach, index) => formData.append(`file${index}`, attach.file));
				const res = await ajaxPromise('/form/submit', formData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Task', 'Task updated successfully.', 'success');
				onCreate();
				success = true;
			}
			catch (error) {
				switch (error.rc) {
					case 'INVALID_RECIPIENT_EMAIL':
						displayNotification('Task', `The following email address is invalid: "${error.email}".  Remember to separate multiple email addresses with commas.`, 'danger');
						break;
					default:
						displayNotification('Task', 'An error occured while updating the task.', 'danger');
						logerror('create task error', error);
						break;
				}
			}

			$submit.prop('disabled', false);

			return success;
		};

		if (postData.recurring && !postData.enddate) {
			displayNotification('Task', 'Please enter a Repeat Until date.', 'warning');
			$submit.prop('disabled', false);
			return;
		}

		if (task && +task.tk_recurring && (+task.tk_latest || task.tk_status == TASK_SCHEDULED)) {
			$('#task_save_msg').text('Would you like to apply all changes to the future scheduled Action Items?');
			$('#task_save_update_repeating_text').text(' Update future scheduled Action Items');
			$('#task_save_update_repeating').prop('checked', false);
			$('#task_save_update_repeating_cont').show();
			$('#task_save_confirm').text('Save Changes').off('click').on('click', async () => {
				$('#task_save_confirm').prop('disabled', true);
				const success = await save({update_repeating: $('#task_save_update_repeating').is(':checked') ? 1 : 0});
				$('#task_save_confirm').prop('disabled', false);
				if (success) $('#task_save_modal').modal('hide');
			});
			$('#task_save_modal').modal('show');
		} else {
			save();
		}
	});

	$cont.find('#task_add_notify').chosen({width: '100%'});

	$submit.off('click').on('click', () => triggerSubmit($cont.find('#task_add_form')));

	refreshRecurDates();

	return {clearCreateTask};
};

if ($('#task_add_modal').length === 1) {
	fileInput($('#task_attachment_file'));
	const taskFormApi = taskForm({$cont: $('#task_add_modal').find('#task_add_body'), $submit: $('#task_add_modal').find('#task_add_submit'), onCreate: () => $('#task_add_modal').modal('hide')});
	$('#task_add_modal').on('hidden.bs.modal', () => taskFormApi.clearCreateTask());
}

if (window.location.pathname.substring(0, 3) == '/ui') {
	$(document).ready(function () {
		$('*').bind('mousemove keydown scroll', function () {
			if (activity % 10 == 0) {
				user_is_active();
				localStorage.setItem('active-event', 'active' + Math.random());
			}
			activity++;
			//$('#buildnumber').html(activity);
		});
		$('body').trigger('mousemove');
	});
}

if (window.location.pathname != '/') {
	// listen for logout signal only if not already logged out
	window.addEventListener('storage', function (event) {
		if (event.key == 'active-event') {
			// Clear warning if it was set
			//$('#buildnumber').html('remote activity');
			clearTimeout(idleLogout);
			$('#inactive_dialog').modal('hide');
			// Do regular activity
			user_is_active();
		}
		if (event.key == 'logout-event') {
			window.location.href = '/logout';
		}
	});
}

if ($('#header_showrelationships').length == 1) {
	$('#header_showrelationships').on('change', function () {
		var btn = $(this);
		btn.bootstrapToggle('disable');
		$('#spinner').show();
		$.post({
			url: '/form/submit',
			data: {
				type: 'user_showrelationships',
				data: {
					value: $('#header_showrelationships').is(':checked') ? 1 : 0,
				},
			},
			complete: function () {
				btn.bootstrapToggle('enable');
				$('#spinner').hide();
			},
			dataType: 'json',
		});
	});
}

const initLogin = () => {
	localStorage.setItem('logout-event', 'logout' + Math.random()); // trigger logout for any other tabs that might still be open

	const lastemail = Cookies.get('lastemail');
	if (lastemail != null) {
		$('#login_form_email').val(lastemail);
		$('#login_form_remember').prop('checked', true);
	}

	$('#login_form_submit').off('click').on('click', async (event) => {
		event.preventDefault();

		// check empty fields
		if ($('#login_form_email').val().toString() == '' || $('#login_form_password').val().toString() == '') {
			displayNotification('Login', 'Please fill out all fields.', 'warning');
			return;
		}

		$('#login_form_spinner').show();
		$('#login_form_submit').prop('disabled', true);

		try {
			const user = {
				email: $('#login_form_email').val().toString(),
				password: $('#login_form_password').val().toString(),
				hostname: location.hostname,
			};

			const res = await ajaxPromise('/login/auth', {data: user});

			if (res.rc !== 'OK') throw 'LOGIN_FAIL';

			if ($('#login_form_remember').is(':checked')) {
				Cookies.set('lastemail', user.email, {expires: 365});
			} else {
				Cookies.remove('lastemail');
			}

			window.location = res.redirect;
		} catch (error) {
			switch (error) {
				case 'LOGIN_FAIL':
					displayNotification('Login', 'Login failed.', 'danger');
					break;
				default:
					displayNotification('Login', 'An error occured while logging in.', 'danger');
					logerror('login_form_submit', error);
			}
		}

		$('#login_form_spinner').hide();
		$('#login_form_submit').prop('disabled', false);
	});

	$('#login_form_sso').off('click').on('click', () => window.location.replace('/sso/login'));

	$('#login_form_sso_admin_login').off('click').on('click', () => {
		$('#login_form_sso_cont').hide();
		$('#login_form').show();
	});

	$('#login_form_back_sso').off('click').on('click', () => {
		$('#login_form_sso_cont').show();
		$('#login_form').hide();
	});

	$('#login_form_forgot').off('click').on('click', (event) => {
		event.preventDefault();
		$('#login_forgot_email').val($('#login_form_email').val());
		$('#login_forgot_modal').modal();

		$('#login_forgot_send').off('click').on('click',  async (event) => {
			event.preventDefault();

			if ($('#login_forgot_email').val().toString() == '') {
				displayNotification('Forgot Password', 'Please enter the email address your account was registered with.', 'danger');
				return;
			}

			try {
				const res = await ajaxPromise('/login/forgotpassword', {email: $('#login_forgot_email').val().toString()});
				if (res.rc !== 'OK') {
					displayNotification('Forgot Password', 'An error occurred while sending this request.', 'danger');
					return;
				}

				displayNotification('Forgot Password', 'Temporary password has been sent.', 'info');
				$('#login_forgot_modal').modal('hide');
			} catch (error) {
				logerror('forgotpassword', error);
			}
		});
	});
};

if ($('#login_form').length == 1) initLogin();

const initMfa = () => {

	const user = JSON.parse(atob($('#mfa_form').data('params')));
	let selectedMethod = null;

	const changeSelectedMethod = (method) => {
		selectedMethod = method;
		switch (method) {
			case MFA_METHOD_EMAIL:
				$('#mfa_form_resend').show();
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.email)}</b>.`);
				break;
			case MFA_METHOD_SMS:
				$('#mfa_form_resend').show();
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.phone)}</b>.`);
				break;
			case MFA_METHOD_APP:
				$('#mfa_form_resend').hide();
				$('#mfa_form_title').html(`Please enter the code displayed on your authenticator app.`);
				break;
		}
	};
	changeSelectedMethod(user.mfa_method);

	$('#mfa_form_submit').off('click').on('click', async (event) => {
		event.preventDefault();

		$('#mfa_form_spinner').show();
		$('#mfa_form_submit').prop('disabled', true);

		try {
			const postData = {
				code: $('#mfa_form_code').val().toString(),
				method: selectedMethod,
				remember: $('#mfa_form_remember').is(':checked') ? 1 : 0,
			};

			const res = await ajaxPromise('/login/mfa', {data: postData});

			if (res.rc !== 'OK') throw res.rc;

			window.location = res.redirect;
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = '';
					break;
				case 'INVALID_CODE':
					displayNotification('Login', 'Incorrect code.', 'danger');
					break;
				default:
					displayNotification('Login', 'An error occured while logging in.', 'danger');
					logerror('mfa_form_submit', error);
			}
		}

		$('#mfa_form_spinner').hide();
		$('#mfa_form_submit').prop('disabled', false);
	});

	$('#mfa_form_resend').off('click').on('click', async (event) => {
		event.preventDefault();

		$('#mfa_form_spinner').show();
		$('#mfa_form_resend').prop('disabled', true);

		try {
			const res = await ajaxPromise('/login/send_mfa', {data: {method: selectedMethod}});

			if (res.rc !== 'OK') throw res.rc;

			displayNotification('Login', 'A new code has been sent.', 'success');
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = '';
					break;
				default:
					displayNotification('Login', 'An error occured while sending the code.', 'danger');
					logerror('mfa_form_resend', error);
			}
		}

		$('#mfa_form_spinner').hide();
		$('#mfa_form_resend').prop('disabled', false);
	});

	$('#mfa_form_change_method').off('click').on('click', () => {
		$('#mfa_method_send').off('click').on('click', async (event) => {
			event.preventDefault();

			const method = +$('#mfa_method_body').find('input[name="mfa_method"]:checked').val();
			if (method == selectedMethod || method == MFA_METHOD_APP) {
				if (method == MFA_METHOD_APP) changeSelectedMethod(method)
				$('#mfa_form_code').val('');
				$('#mfa_method_modal').modal('hide');
				return;
			}

			$('#mfa_method_send').prop('disabled', true);

			try {
				const res = await ajaxPromise('/login/send_mfa', {data: {method}});

				if (res.rc !== 'OK') throw res.rc;
				changeSelectedMethod(method);

				displayNotification('Login', 'A new code has been sent.', 'success');
				$('#mfa_method_modal').modal('hide');
			} catch (error) {
				switch (error) {
					case 'NO_MFA_LOGIN':
						window.location.href = '';
						break;
					default:
						displayNotification('Login', 'An error occured while sending the code.', 'danger');
						logerror('mfa_form_resend', error);
				}
			}

			$('#mfa_method_send').prop('disabled', false);
		});
		$('#mfa_form_code').val('');
		$('#mfa_method_modal').modal('show');
	});
};

if ($('#mfa_form').length == 1) initMfa();

type printThisParams = {
	title: string,
	callback(): $,
};
export const printThis = async (config: printThisParams) => {
	try {
		const printWin = window.open('');
		printWin.document.body.style.pointerEvents = 'none';

		const $head = $('head').clone();
		$head.find('title').html(htmlEsc(config.title) + ' | 3rd Party Toolbox');

		await setTimeoutPromise(50);

		printWin.document.write($head.html());

		const $content = config.callback();
		printWin.document.write($content.html());

		await setTimeoutPromise(250);

		printWin.print();
	} catch (error) {
		logerror('print_this', error);
	}
}

type questionSetLoadModalParams = {
	type: number,
	onQsetSelected(rowData: any): void,
}
let questionSetDt: DataTables.Api = null;
export const questionSetLoadModal = ({type, onQsetSelected}: questionSetLoadModalParams) => {
	if (questionSetDt != null) {
		questionSetDt.destroy();
	}
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/questionsets_load',
			data: (postData) => ({
				...postData,
				type,
			}),
		},
		columns: [
			{
				data: 'qs_id',
				visible: false,
				orderable: false,
				searchable: false,
			},
			{
				title: 'Label',
				data: 'qs_label',
			},
			{
				title: 'Questions',
				data: 'qs_data_nice',
				render: null,
			},
			{
				data: 'qs_data',
				visible: false,
				orderable: false,
				searchable: false,
			},
			{
				title: '',
				data: 'button1',
				orderable: false,
				searchable: false,
				className: 'text-right',
				width: '3em',
			},
			{
				title: 'Actions',
				data: 'button2',
				orderable: false,
				searchable: false,
				className: 'text-right',
				width: '3em',
			},
		],
		columnDefs: [
			{ targets: 0, responsivePriority: 1 },
			{ targets: [-1, -2], responsivePriority: 2, render: null },
			{ targets: '_all', render: (val) => htmlEsc(val) },
		],
		order: [[1, 'desc']],
	};

	const questionSetDeleteReset = () => {
		$('#questionset_table').prop('disabled', false);
		$('#questionset_delete_cancel').off('click');
		$('#questionset_delete_confirm').off('click');
		$('#questionset_delete_container').collapse('hide');
	}

	const questionSetDelete = (qSetId: number) => {
		setTimeout(() => $('#questionset_form_load_container').scrollTop(0), 250);
		$('#questionset_delete_container').collapse('show');
		highlight($('#questionset_delete_container'));

		$('#questionset_delete_cancel').off('click').on('click', () => questionSetDeleteReset());

		$('#questionset_delete_confirm').off('click').on('click', async () => {
			$('#questionset_delete_container button').prop('disabled', true);

			try {
				const postData = {
					type: 'contract_questionset_delete',
					data: JSON.stringify({
						id: qSetId,
					}),
					json: 1,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				questionSetDt.ajax.reload();
				displayNotification('Delete Success', 'The question set was deleted successfully.', 'success');
			}
			catch (error) {
				logerror('question set delete error [ajax]', error);
				displayNotification('Delete Error', 'The question set cannot be deleted.', 'danger');
			}
			questionSetDeleteReset();
		});

		$('#questionset_delete_container button').prop('disabled', false);
	}

	questionSetDt = $('#questionset_table').DataTable(dtOptions);

	$('#questionset_table').off('click').on('click', '.questionset_load', ({ target }) => {
		const rowData = getDtRowData(questionSetDt, target);
		if (onQsetSelected) onQsetSelected(rowData);
		$('#questionset_form_load_container').modal('hide');
		displayNotification('Load Success', 'The question set has been loaded.', 'success');
		return false;
	});

	$('#questionset_table').on('click', '.questionset_delete', ({ target }) => {
		const rowData = getDtRowData(questionSetDt, target);
		questionSetDelete(+rowData.qs_id);
	});

	$('#questionset_form_load_container').modal();
}

// Prep the charts
const highchartsOptions: Highcharts.Options = {
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		//plotShadow: false,
		//spacing: [0,0,0,0],
	},
	plotOptions: {
		line: {
			cursor: 'pointer',
		},
		pie: {
			innerSize: '50%',
			depth: 45,
			allowPointSelect: true,
			cursor: 'pointer',
			size: '75%',
			dataLabels: {
				enabled: false,
			},
			showInLegend: true,
		},
		series: {
			marker: {
				enabled: true,
			},
			animation: false,
		},
	},
	title: {
		margin: 0,
		//floating: true
	},
	subtitle: {
		floating: true,
	},
	credits: {
		enabled: false,
	},
	tooltip: {
		pointFormat: '{point.y} <b>({point.percentage:.1f}%)</b>',
		//overflow:
	},
};

Highcharts.setOptions(highchartsOptions);

export const highcharts_pie: Highcharts.Options = {
	chart: {
		type: 'pie',
		margin: [0, 0, 90, 0],
		height: '150%',
		options3d: {
			enabled: true,
			alpha: 45,
		},
	},
	legend: {
		labelFormat: '{name} ({percentage:.1f}%) {y}',
		itemStyle: {
			textOverflow: null,
		},
	},
};

export const highcharts_line: Highcharts.Options = {
	chart: {
		type: 'line',
		height: '50%',
	},
	legend: {
		enabled: false,
	},
	xAxis: {
		type: 'datetime',
		dateTimeLabelFormats: {
			millisecond: '%b %e %Y',
			second: '%b %e %Y',
			minute: '%b %e %Y',
			hour: '%b %e %Y',
			day: '%b %e %Y',
			week: '%b %e %Y',
			month: '%b %Y',
			year: '%Y',
		},
	},
};

// Sparkline Definition
export var sparkline_defaults: Highcharts.Options = {
	chart: {
		//renderTo: (options.chart && options.chart.renderTo) || this,
		backgroundColor: null,
		borderWidth: 0,
		height: '50%',
		type: 'line',
		style: {
			overflow: 'visible',
		},
	},
	title: {
		text: '',
	},
	credits: {
		enabled: false,
	},
	exporting: {
		enabled: false,
	},
	xAxis: {
		lineWidth: 0,
		tickWidth: 0,
		startOnTick: true,
		endOnTick: true,
		type: 'datetime',
		dateTimeLabelFormats: {
			millisecond: '%b %e %Y',
			second: '%b %e %Y',
			minute: '%b %e %Y',
			hour: '%b %e %Y',
			day: '%b %e %Y',
			week: '%b %e %Y',
			month: '%b %Y',
			year: '%Y',
		},
	},
	yAxis: {
		startOnTick: true,
		endOnTick: true,
	},
	legend: {
		enabled: false,
	},
	tooltip: {},
	plotOptions: {
		series: {
			animation: false,
			lineWidth: 1,
			shadow: false,
			cursor: 'pointer',
			states: {
				hover: {
					lineWidth: 2,
				},
			},
			marker: {
				radius: 4,
			},
			//fillOpacity: 0.25
		},
		column: {
			negativeColor: '#910000',
			borderColor: 'silver',
		},
	},
};

export const vendorPerformanceTooltipFormatter = (data, thresholds) => {
	let html = `<b>${getMonthNameShort(data.x)}</b><br>`;

	data.points
		.sort((a, b) => +b.y - +a.y)
		.forEach((point, index, points) => {
			if (index == 0 || points[index - 1].y !== point.y) {
				html += `<br><b>${htmlEsc(thresholds[point.y])}:</b>`;
			}
			html += `<br>${htmlEsc(point.series.name)} (${point.point.percent})%`;
		});

	return html;
};

export const contractOverallPerformanceTooltipFormatter = (data, thresholds) => `<b>${getMonthNameShort(data.x)}</b><br>${htmlEsc(thresholds[data.y])}<br>${htmlEsc(data.point.percent)}%`;

export const contractPerformanceTooltipFormatter = (
	data // Question score with comment
) => `${getDateShort(+data.x)}<br><b>${htmlEsc(data.point.text)} </b>(${htmlEsc(data.point.value)})`;

// Charts build
export const buildCharts = (res) => {
	Object.keys(globalCharts).forEach((chart) => {
		if (globalCharts[chart] !== null) {
			globalCharts[chart].destroy();
			globalCharts[chart] = null;
		}
	})

	if (res.rc !== 'OK') {
		$('#panel-charts-message').html('There is no Vendor data to show.').show();
		$('#panel-charts .row>div').each((_, element) => {
			$(element).find('div').html('');
			$(element).hide();
		});
	}

	$('#panel-charts-message').html('').hide();

	// Build the charts
	$('#chart-inherentrisk').parent().hide();
	if (res.hasOwnProperty('vend_inherent') && res.vend_inherent.length > 0 && $('#chart-inherentrisk').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_pie)),
			title: {
				text: 'Inherent',
			},
			series: [
				{
					name: 'Vendors',
					data: res.vend_inherent.map((val) => ({...val, name: htmlEsc(val.name)})),
				},
			],
		};
		globalCharts.inherent = Highcharts.chart('chart-inherentrisk', chartOptions);
		$('#chart-inherentrisk').parent().show();
	}
	$('#chart-critical').parent().hide();
	if (res.hasOwnProperty('vend_critical') && res.vend_critical.length > 0 && $('#chart-critical').length == 1) {
		var chartoptions = JSON.parse(JSON.stringify(highcharts_pie));
		chartoptions.colors = ['#00bf00', '#e80000'];
		chartoptions.title = {
			text: 'Critical',
		};
		chartoptions.series = [
			{
				name: 'Vendors',
				data: res.vend_critical,
			},
		];
		globalCharts.critical = Highcharts.chart('chart-critical', chartoptions);
		$('#chart-critical').parent().show();
	}
	$('#chart-residualrisk').parent().hide();
	if (res.hasOwnProperty('vend_residual') && res.vend_residual.length > 0 && $('#chart-residualrisk').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_pie)),
			title: {
				text: 'Residual',
			},
			series: [
				{
					name: 'Vendors',
					data: res.vend_residual.map((val) => ({...val, name: htmlEsc(val.name)})),
				},
			],
		};
		globalCharts.residual = Highcharts.chart('chart-residualrisk', chartOptions);
		$('#chart-residualrisk').parent().show();
	}
	$('#chart-slascore').parent().hide();
	if (res.hasOwnProperty('vend_sla') && res.vend_sla.length > 0 && $('#chart-slascore').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_pie)),
			title: {
				text: 'SLA',
			},
			series: [
				{
					name: 'Vendors',
					data: res.vend_sla.map((val) => ({...val, name: htmlEsc(val.name)})),
				},
			],
		};
		globalCharts.sla = Highcharts.chart('chart-slascore', chartOptions);
		$('#chart-slascore').parent().show();
	}
	$('#chart-kpiscore').parent().hide();
	if (res.hasOwnProperty('vend_kpi') && res.vend_kpi.length > 0 && $('#chart-kpiscore').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_pie)),
			title: {
				text: 'KPI',
			},
			series: [
				{
					name: 'Vendors',
					data: res.vend_kpi.map((val) => ({...val, name: htmlEsc(val.name)})),
				},
			],
		};
		globalCharts.kpi = Highcharts.chart('chart-kpiscore', chartOptions);
		$('#chart-kpiscore').parent().show();
	}
	$('#chart-vendvalue').parent().hide();
	if (res.hasOwnProperty('vend_vendvalue') && res.vend_vendvalue.length > 0 && $('#chart-vendvalue').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_pie)),
			title: {
				text: 'Value',
			},
			series: [
				{
					name: 'Vendors',
					data: res.vend_vendvalue.map((val) => ({...val, name: htmlEsc(val.name)})),
				},
			],
		};
		globalCharts.vendValue = Highcharts.chart('chart-vendvalue', chartOptions);
		$('#chart-vendvalue').parent().show();
	}
	$('#chart-performance-sla').parent().hide();
	if (res.hasOwnProperty('vend_performance_sla') && $('#chart-performance-sla').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_line)),
			title: {
				text: 'SLA Performance',
			},
			xAxis: {
				type: 'datetime',
				dateTimeLabelFormats: {
					month: '%b %y',
					year: '%Y',
				},
				title: {
					text: 'Date',
				},
				min: res.vend_performance_range[0],
				max: res.vend_performance_range[1],
			},
			yAxis: {
				categories: res.sla_thresholds.map((val) => htmlEsc(val)),
				title: {
					enabled: false,
				},
			},
			tooltip: {
				formatter: function () {
					return vendorPerformanceTooltipFormatter(this, res.sla_thresholds);
				},
				shared: true,
			},
			series: res.vend_performance_sla.map((val) => ({...val, name: htmlEsc(val.name)})),
		};
		globalCharts.performanceSla = Highcharts.chart('chart-performance-sla', chartOptions);
		$('#chart-performance-sla').parent().show();
	}
	$('#chart-performance-kpi').parent().hide();
	if (res.hasOwnProperty('vend_performance_kpi') && $('#chart-performance-kpi').length == 1) {
		const chartOptions = {
			...JSON.parse(JSON.stringify(highcharts_line)),
			title: {
				text: 'KPI Performance',
			},
			xAxis: {
				type: 'datetime',
				dateTimeLabelFormats: {
					month: '%b %y',
					year: '%Y',
				},
				title: {
					text: 'Date',
				},
				min: res.vend_performance_range[0],
				max: res.vend_performance_range[1],
			},
			yAxis: {
				categories: res.kpi_thresholds.map((val) => htmlEsc(val)),
				title: {
					enabled: false,
				},
			},
			tooltip: {
				formatter: function () {
					return vendorPerformanceTooltipFormatter(this, res.kpi_thresholds);
				},
				shared: true,
			},
			series: res.vend_performance_kpi.map((val) => ({...val, name: htmlEsc(val.name)})),
		};
		globalCharts.performanceKpi = Highcharts.chart('chart-performance-kpi', chartOptions);
		$('#chart-performance-kpi').parent().show();
	}
}

const initAllDocuments = () => {
	const reqdocs = JSON.parse(atob($('#document_edit_container').data('reqdocs')));
	const doctypeDescs = JSON.parse(atob($('#document_form_type').data('descriptions')));
	const documentAdd = !!$('#all_documents_form_container').data('add');

	const getFilters = () => ({
		vendor: $('#document_filter_vendor').val(),
		type: $('#document_filter_doctype').val(),
		required: $('#document_filter_required').val(),
	});
	const setFilters = (filters) => {
		$('#document_filter_vendor').val(filters.vendor).trigger('chosen:updated');
		$('#document_filter_doctype').val(filters.type).trigger('chosen:updated');
		$('#document_filter_required').val(filters.required).trigger('chosen:updated');
		$('#document_filter_container select.chosen').each((_, el) => checkChosenChanged($(el)));
		doctDt.ajax.reload();
	};
	const defaultFilters = getFilters();
	const resetFilters = () => setFilters(defaultFilters);

	$('#document_filter_container select.chosen').on('change', ({target}) => checkChosenChanged($(target)));
	$('#document_filter_reset').on('click', () => resetFilters());

	fileInput($('#document_form_file'));

	let dtButtons: (DataTables.ButtonSettings & {admin?: boolean})[] = [
		{
			text: '<i class="fa fa-plus me-2"></i> Add Document',
			className: 'ml-2 btn btn-sm btn-success',
			action: () => document_edit({id: 0}),
			admin: true,
		},
		{
			text: '<i class="fa fa-envelope me-2"></i> Send',
			className: 'send ml-2 btn btn-sm btn-info',
			action: () => {
				const docs = senddableDocs();
				sendDocuments(docs);
			},
		},
		{
			text: '<i class="fa fa-file-csv me-2"></i> Export',
			className: 'ml-2 btn btn-sm btn-secondary',
			action: ({target}) => {
				const filters = {
					...getFilters(),
					doc: doctDt.rows({ selected: true }).data().toArray().map((doc) => +doc.doc_id),
					all_documents: 1,
				};

				// Submit the form
				$(target).prop('disabled', true);
				post('/export', { type: 'documents', data: JSON.stringify(filters) });
				setTimeout(() => $(target).prop('disabled', false), 1000);
			},
			admin: true,
		},
	];
	if (!documentAdd) dtButtons = dtButtons.filter((btn) => !btn.admin);

	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/documents_load',
			data: (postData) => ({
				...postData,
				filters: getFilters(),
				all_documents: 1,
			}),
		},
		buttons: dtButtons,
		columns: [
			{
				data: null,
				defaultContent: '',
				orderable: false,
				searchable: false,
				className: 'select-checkbox',
			},
			{
				title: 'Vendor',
				data: 'vend_name',
			},
			{
				title: 'Title',
				data: 'doc_title',
			},
			{
				title: 'Type',
				data: 'doct_name',
				searchable: false,
			},
			{
				title: 'Required Document',
				data: 'reqdoc_active',
				searchable: false,
			},
			{
				title: 'Effective Date',
				data: 'doc_effectivedate',
				searchable: false,
			},
			{
				title: 'Actions',
				data: 'buttons',
				className: 'text-right',
				orderable: false,
				searchable: false,
			},
		],
		columnDefs: [
			{ targets: [0, 2], responsivePriority: 1 },
			{ targets: [0, -1], responsivePriority: 2, render: null },
			{ targets: '_all', render: (val) => htmlEsc(val) },
		],
		order: [[5, 'desc']],
		select: {
			style: 'os',
			selector: 'td:first-child',
		},
	};

	const doctDt = $('#documents_table').DataTable(dtOptions);

	const senddableDocs = () => doctDt.rows({ selected: true }).data().toArray().filter((doc) => +doc.doc_external === 0);

	doctDt.on('draw', () => {
		$('#documents_table').find('.select-all').removeClass('selected').off('click').on('click', ({target}) => {
			$(target).toggleClass('selected')
			if ($(target).hasClass('selected')) doctDt.rows().select();
			else doctDt.rows().deselect();
		});
		doctDt.buttons(['.send']).disable();
	});

	doctDt.on('select', () => {
		if (senddableDocs().length) doctDt.buttons(['.send']).enable();
	});

	doctDt.on('deselect', () => {
		if (!senddableDocs().length) doctDt.buttons(['.send']).disable();
	});

	$('#header_showrelationships, #document_filter_container select').on('change', () => doctDt.ajax.reload());

	$('#documents_table').on('click', '.document_view', ({ target }) => {
		const rowData = getDtRowData(doctDt, target);
		window.open(`/document/get/${rowData.doc_hash}`, '_blank');
	});

	//Document send modal reset when hidden
	$('#document_send_container').on('hidden.bs.modal', () => {
		$('#document_send_input').val('');
		$('#document_send_comments').val('');
		$('#document_send_submit').off('click');
	});

	const sendDocuments = (docs) => {
		//Modal 'Send' handler
		$('#document_send_submit').on('click', async (event) => {
			event.preventDefault();
			const emails = $('#document_send_input').val().toString();
			if (!emails) {
				displayNotification('Document Send', 'Please enter an email address.', 'danger');
				return;
			}

			try {
				const postData = {
					type: 'document_send',
					data: {
						ids: docs.map((doc) => +doc.doc_id),
						emails,
						comments: $('#document_send_comments').val().toString(),
					},
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Document Send', 'Document sent succesfully.', 'success');
				$('#document_send_container').modal('hide');
			} catch (error) {
				switch (error.rc) {
					case 'BAD_EMAIL':
						displayNotification('Document Send', `The following email address is invalid: "${error.email}".  Remember to separate multiple email addresses with commas.`, 'danger');
						break;
					default:
						displayNotification('Document Send', 'There was an error sending the document.', 'danger');
						logerror('document_send_submit', error);
				}
			}
		});

		//Show modal
		$('#document_send_container').modal('show');
	};

	$('#documents_table').on('click', '.document_send', ({ target }) => {
		const doc = getDtRowData(doctDt, target);
		sendDocuments([doc]);
	});

	$('#documents_table').on('click', '.document_edit', ({ target }) => {
		const rowData = getDtRowData(doctDt, target);
		document_edit({
			id: rowData.doc_id,
			title: rowData.doc_title,
			type: rowData.doc_doctype,
			vendid: rowData.doc_vendid,
			issuedate: rowData.doc_issuedate,
			description: rowData.doc_description,
			external: parseInt(rowData.doc_external),
			exception: parseInt(rowData.doc_exception),
			original: rowData.doc_original,
			content: rowData.doc_content,
			size: rowData.doc_size,
		});
	});

	$('#document_filter_container select.chosen').chosen({ width: '100%' });
	toggleSlide($('#document_filter_open'), $('#document_filter_container'));

	/*
	// This was set up outside of document_edit because the 'change' event cannot be unbound!
	// If I unbind/rebind it inside of document_edit, it would lose the toggle button bind.
	*/
	$('#document_form_file_external').on('change', function () {
		let checked = $(this).is(':checked');
		if (checked) {
			$('#document_form_file_size').css({ visibility: 'hidden' });
			$('#document_form_file').val('').trigger('change');
			$('#document_form_file').parent().hide();
		} else {
			$('#document_form_file_size').css({ visibility: 'visible' });
			$('#document_form_file').parent().show();
		}
		//logme('external change?');
		checkNotify();
	});

	$('#document_form_exception').on('change', function () {
		let checked = $(this).is(':checked');
		if (checked) {
			$('#document_form_file_container_both').hide();
		} else {
			$('#document_form_file_container_both').show();
		}
	});

	function checkNotify() {
		// Workflow Email Notification Setting
		let cont = $('#document_form_file_container').is(':visible'),
			disp = !$('#document_form_file_external').is(':checked'),
			cati = $('#document_form_vendor option:selected').data('category'),
			doct = $('#document_form_type').val().toString();

		// Unset all first.
		$('#document_form_email_manual_notice').hide();
		$('#document_form_email_manual_input').val('');

		$('#document_form_email_auto_notice').hide();

		$('#document_form_email_both_notice').hide();
		$('#document_form_email_both_notice_list').html('');
		$('#document_form_email_both_input').val('');

		if (cont && disp && reqdocs.hasOwnProperty(cati) && reqdocs[cati].hasOwnProperty(doct)) {
			switch (parseInt(reqdocs[cati][doct].reqdoc_notify)) {
				case NOTIFY_MANUAL:
					$('#document_form_email_manual_notice').show();
					$('#document_form_email_manual_input').val(reqdocs[cati][doct].reqdoc_emails);
					break;
				case NOTIFY_AUTO:
					if (reqdocs[cati][doct].reqdoc_emails !== null) {
						$('#document_form_email_auto_notice').show();
					}
					break;
				case NOTIFY_MANUAL_AND_AUTO:
					if (reqdocs[cati][doct].reqdoc_emails !== null) {
						const emails = reqdocs[cati][doct].reqdoc_emails.split(',');
						const emailList = (
							<ul>
								{emails.map((email) => <li>{email}</li>)}
							</ul>
						);
						$('#document_form_email_both_notice_list').html(emailList);
					} else {
						$('#document_form_email_both_notice_list').html('<ul><li>No email addresses were defined.</li></ul>');
					}
					$('#document_form_email_both_notice').show();
					break;
			}
		}
	}

	function document_edit(document) {
		//logme('document_edit:');
		//logme(document);
		let mode = document.id === 0 ? 0 : 1;
		$('#document_form').data('mode', mode);

		let required = false;

		setTimeout(function () {
			highlight($('#document_edit_container'));
			$(window).scrollTop($('#document_edit_container').offset().top - 400);
		}, 400);

		$('#document_form_vendor').off('change');
		$('#document_form_vendor').on('change', function () {
			checkReqDoc();
			checkNotify();
		});

		if (mode === 0) {
			// add
			$('#document_form_submit').html('Save');
			$('#document_form_title').val('');
			$('#document_form_type').prop('selectedIndex', 0);
			$('#document_form_issuedate').val('');
			$('#document_form_description').val('');

			$('#document_form_file').val('').trigger('change');
			$('#document_form_file_external').bootstrapToggle('off');
			$('#document_form_exception').bootstrapToggle('off');
			$('#document_form_file').prop('required', true);
			$('#document_form_file_cancel').off('click').hide();
			$('#document_form_file_change').off('click').hide();

			$('#document_form_file_stored_container').hide();
			$('#document_form_file_container').show();
			$('#document_form_file_stored_original').html('');
			$('#document_form_file_stored_content').html('');
			$('#document_form_file_stored_size').html('');

			$('#document_form_archive').off('click').hide();

			doctype_display_description();
			checkReqDoc();

			$('#document_form_type').off('change');
			$('#document_form_type').on('change', function () {
				doctype_display_description();
				checkReqDoc();
				checkNotify();
			});

			$('#document_form_notify').off('click').hide();
		} // edit
		else {
			$('#document_form_submit').html('Update');
			$('#document_form_title').val(document.title);
			$('#document_form_type').val(document.type);
			$('#document_form_vendor').val(document.vendid);
			$('#document_form_issuedate').val(document.issuedate);
			$('#document_form_description').val(document.description);

			document.cati = $('#document_form_vendor option:selected').data('category');
			let allow_notify = reqdocs.hasOwnProperty(document.cati) && reqdocs[document.cati].hasOwnProperty(document.type) && [1, 3].includes(parseInt(reqdocs[document.cati][document.type].reqdoc_notify));

			$('#document_form_type').off('change');
			$('#document_form_type').on('change', function () {
				doctype_display_description();
				checkReqDoc();
				checkNotify();
			});

			let isException = document.exception === 1;
			let isExternal = document.external === 1;
			$('#document_form_exception').bootstrapToggle(isException ? 'on' : 'off');
			$('#document_form_file_external').bootstrapToggle(isExternal ? 'on' : 'off');

			setTimeout(function () {
				checkNotify();
			}, 1);

			$('#document_form_file').val('').trigger('change');
			$('#document_form_file').prop('required', false);

			if (isExternal) {
				// is external
				$('#document_form_file_container').show();
				$('#document_form_file_stored_container').hide();
				$('#document_form_file_cancel').off('click').hide();
				$('#document_form_notify').off('click').hide();
			} else {
				// is internal / file is in system
				$('#document_form_file_stored_container').show();
				$('#document_form_file_container').hide();
				$('#document_form_file_stored_original').html(htmlEsc(document.original));
				$('#document_form_file_stored_content').html(htmlEsc(document.content));
				$('#document_form_file_stored_size').html(filesizePretty(+document.size));

				$('#document_form_file_change').off('click');
				$('#document_form_file_change')
					.on('click', function () {
						$('#document_form_file_container').show();
						$('#document_form_file_stored_container').hide();
						checkNotify();
						$('#document_form_notify').hide();
					})
					.show();

				$('#document_form_file_cancel').off('click');
				$('#document_form_file_cancel')
					.on('click', function () {
						$('#document_form_file').val('').trigger('change');
						$('#document_form_file_container').hide();
						$('#document_form_file_stored_container').show();
						$('#document_form_file_external').bootstrapToggle(isExternal ? 'on' : 'off');
						checkNotify();
						if (allow_notify) {
							// If Manual or Both
							$('#document_form_notify').show();
						}
					})
					.show();

				if (allow_notify) {
					// If Manual or Both
					// modal clear
					$('#document_notify_cancel').off('click');
					$('#document_notify_cancel').on('click', function () {
						$('#document_notify_container').modal('hide');
					});
					$('#document_notify_container').off('hidden.bs.modal');
					$('#document_notify_container').on('hidden.bs.modal', function () {
						$('#document_notify_input').val('');
					});

					$('#document_form_notify').show().off('click').on('click', (event) => {
						event.preventDefault();

						// open the modal
						$('#document_notify_target').html(htmlEsc(document.title));
						$('#document_notify_container').modal({ backdrop: 'static' });

						$('#document_notify_submit').off('click').on('click', async (event) => {
							event.preventDefault();

							const $btn = $(event.target);
							$btn.prop('disabled', true);
							$('#spinner').show();

							try {
								const postData = {
									type: 'document_notify',
									data: {
										id: document.id,
										emails: $('#document_notify_input').val(),
									},
								};
								const res = await ajaxPromise('/form/submit', postData);
								if (res.rc !== 'OK') throw res;

								displayNotification('Notification Send', 'The notification has been sent successfully.', 'success');
								$('#document_notify_container').modal('hide');
							} catch (error) {
								displayNotification('Notification Send Error', 'There was an error preparing the notification.', 'danger');
								logerror('document notify submit', error);
							}

							$btn.prop('disabled', false);
							$('#spinner').hide();
						});
					});
				} else {
					$('#document_form_notify').off('click').hide();
				}
			}

			if (isException) {
				//logme('hide file input');
				$('#document_form_file_container_both').hide();
			}

			$('#document_form_archive').show().off('click').on('click', async (event) => {
				event.preventDefault();

				const confirmed = await confirmDialog({
					dialogTitle: 'Document Delete',
					bodyText: 'Are you sure you would like to delete this document?',
					confirmText: 'Delete',
					confirmStyle: 'danger',
				});
				if (!confirmed) return;

				try {
					const postData = {
						type: 'document_archive',
						data: document.id,
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Delete Success', 'The document was deleted successfully.', 'success');
					doctDt.ajax.reload();
					closeform();
				} catch (error) {
					displayNotification('Delete Error', 'There was an error deleting this Document.', 'danger');
					logerror('document archive', error);
				}
			});

			doctype_display_description();
			checkReqDoc();
		}
		$('#document_edit_container').collapse('show');

		function checkReqDoc() {
			// different than the vendor edit counterpart!
			let cat = $('#document_form_vendor option:selected').data('category'),
				doct = $('#document_form_type').val().toString();
			$('#document_form_reqdoc_notice').hide();
			required = false;
			if (reqdocs.hasOwnProperty(cat) && reqdocs[cat].hasOwnProperty(doct) && parseInt(reqdocs[cat][doct].reqdoc_active) > 0) {
				// category in set, doc type in set, check active flag
				$('#document_form_reqdoc_notice').show();
				required = true;
			}
		}

		function doctype_display_description() {
			let val = $('#document_form_type').val().toString();
			if (doctypeDescs.hasOwnProperty(val)) {
				$('#document_form_type_description').html(htmlEsc(doctypeDescs[val]));
			} else {
				$('#document_form_type_description').html('<i>No Document Type Description given</i>');
			}
		}

		function pullEmailNotify() {
			let cont = $('#document_form_file_container').is(':visible'),
				cati = $('#document_form_vendor option:selected').data('category'),
				doct = $('#document_form_type').val().toString();

			if (cont && reqdocs.hasOwnProperty(cati) && reqdocs[cati].hasOwnProperty(doct)) {
				let notify = parseInt(reqdocs[cati][doct].reqdoc_notify);
				switch (notify) {
					case NOTIFY_MANUAL:
						return $('#document_form_email_manual_input').val();
					case NOTIFY_AUTO:
						return '';
					case NOTIFY_MANUAL_AND_AUTO:
						return $('#document_form_email_both_input').val();
					default:
						return '';
				}
			}
			return '';
		}

		function closeform() {
			$('#document_form_vendor').val('');
			$('#document_form_title').val('');
			$('#document_form_type').prop('selectedIndex', 0);
			$('#document_form_file').val('').trigger('change');
			$('#document_form_email_manual_input').val('');
			$('#document_form_email_both_input').val('');
			$('#document_edit_container').collapse('hide');
			$('#document_form_notify').off('click').hide();
		}

		$('#document_form_cancel').off('click');
		$('#document_form_cancel').on('click', function () {
			closeform();
			return false;
		});

		$('#document_form_submit').off('click');
		$('#document_form_submit').on('click', function (e) {
			$('#document_form').trigger('submit');
			return false;
		});

		const saveDoc = async (formData: FormData) => {
			$('#document_form_submit').prop('disabled', true);

			try {
				const res = await ajaxPromise('/form/submit', formData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Save Success', 'The document was saved successfully.', 'success');
				doctDt.ajax.reload();
				closeform();
			} catch (error) {
				displayNotification('Save Error', 'There was an error saving this document.', 'danger');
				logerror('document submit', error);
			}

			$('#document_form_submit').prop('disabled', false);
		};

		$('#document_form').off('submit').on('submit', async (event) => {
			event.preventDefault();

			let data = JSON.parse(JSON.stringify(document));
			data.title = $('#document_form_title').val();
			data.type = $('#document_form_type').val();
			data.vendid = $('#document_form_vendor').val();
			data.issuedate = $('#document_form_issuedate').val();
			data.description = $('#document_form_description').val();
			data.external = $('#document_form_file_external').is(':checked') ? 1 : 0;
			data.exception = $('#document_form_exception').is(':checked') ? 1 : 0;
			data.file = $('#document_form_file').val();
			data.emails = pullEmailNotify();
			let bad = false;

			if (data.title == null || data.title.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please fill out the document title.', 'danger');
			}
			if (data.type == null || data.type.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please choose a document type.', 'danger');
			}
			if (data.vendid == null || data.vendid.length == 0) {
				bad = true;
				displayNotification('Save Error', 'Please choose a vendor.', 'danger');
			}
			if (data.issuedate == null || data.issuedate.length == 0) {
				if (required) {
					bad = true;
					displayNotification('Save Error', 'Document is required; Please enter an Effective Date.', 'danger');
				} else if (data.exception) {
					bad = true;
					displayNotification('Save Error', 'Document is an exception; Please enter an Effective Date.', 'danger');
				}
			}
			if (data.exception === 1) {
				data.external = 1;
				data.file = '';
			}
			if (data.external === 0) {
				if ((data.id === 0 || document.external === 1) && (data.file == '' || data.file.length == 0)) {
					bad = true;
					displayNotification('Save Error', 'Please include a file.', 'danger');
				}
				const formFile = $('#document_form_file').get(0) as HTMLInputElement;
				if (data.file.length > 0 && formFile.files[0].size >= 26214400) {
					bad = true;
					displayNotification('Save Error', 'The file chosen exceeds the size limit of 25MB.', 'danger');
				}
			} else {
				data.file = '';
			}

			if (bad) return;

			const formData = new FormData($('#document_form').get(0) as HTMLFormElement);
			formData.set('type', 'document_save');
			formData.set('data', JSON.stringify(data)); // object data is weird.

			if (data.id !== 0 && data.external === 0 && data.file != null && data.file.length > 0) {
				const confirmed = await confirmDialog({
					dialogTitle: 'Document Replace',
					bodyText: 'Are you sure you would like to replace the file? The original file will be overwritten.',
					confirmText: 'Replace',
					confirmStyle: 'warning',
				});
				if (!confirmed) return;
			} else if (data.id !== 0 && document.external === 0 && data.external === 1) {
				const confirmed = await confirmDialog({
					dialogTitle: 'Document Replace',
					bodyText: 'Are you sure you would like to remove the file? The stored file will be removed and this document will be labeled external.',
					confirmText: 'Replace',
					confirmStyle: 'warning',
				});
				if (!confirmed) return;
			}

			saveDoc(formData);
		});
	}
}

if ($('#all_documents_form_container').length == 1) initAllDocuments();

export const assessmentsTableBuild = ({$table, vend_id, type, con_guid = null}: {$table: $, vend_id: number, type: number, con_guid?: string}) => {
	let dtColumns: (DataTables.ColumnSettings & {assessType?: number[]})[] = [
		{
			title: 'Date',
			data: 'surveyresp_date_nice',
		},
		{
			title: 'Set',
			data: 'cset_name',
			assessType: [ASSESSMENT_CONCHECKLIST],
		},
		{
			title: 'Reviewer',
			data: 'surveyresp_user_nice',
		},
		{
			title: 'Rating',
			data: 'rating',
		},
		{
			title: 'Status',
			data: 'surveyresp_status_nice',
		},
		{
			title: 'Category',
			data: 'cat_name',
			assessType: [ASSESSMENT_DILIGENCE, ASSESSMENT_REVIEW],
		},
		{
			title: 'Actions',
			data: 'buttons',
			className: 'text-right nowrap',
			orderable: false,
			searchable: false,
			width: '3em',
		},
	];
	dtColumns = dtColumns.filter((col) => !col.assessType || col.assessType.includes(type));
	const dtColIndicies = dtColumnIndicies(dtColumns);
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/responses_load',
			data: (postData) => ({
				...postData,
				vendorid: vend_id,
				categorytype: type,
				con_guid,
			}),
		},
		columns: dtColumns,
		columnDefs: [
			{ targets: dtColIndicies.surveyresp_date_nice, responsivePriority: 1 },
			{ targets: dtColIndicies.buttons, responsivePriority: 2, render: null },
			{ targets: '_all', render: (val) => htmlEsc(val) },
		],
		order: [[dtColIndicies.surveyresp_date_nice, 'desc']],
	};

	const dt = $table
	.on('init.dt, draw.dt', () => {
		dt.rows().eq(0).each((index) => {
			const row = dt.row(index);
			if (row.data().uncompleted_task) $(row.node()).css({ 'background-color': '#f39c12' });
		});
	})
	.DataTable(dtOptions);

	$table.on('click', '.response_print', ({ target }) => {
		const rowData = getDtRowData(dt, target);
		post('/printout/assessment', { surveyresp_guid: rowData.surveyresp_guid }, '_blank');
	});

	$table.on('click', '.response_export', ({ target }) => {
		const rowData = getDtRowData(dt, target);
		post('/export/response', { surveyresp_guid: rowData.surveyresp_guid }, '_blank');
	});

	$table.on('click', '.response_edit', ({ target }) => {
		const rowData = getDtRowData(dt, target);
		post('/ui/assessment', {
			surv_guid: rowData.surveyresp_guid,
			vend_id,
			cat_id: rowData.surveyresp_cat_id,
			type,
		});
	});

	$table.on('click', '.response_delete', ({ target }) => {
		const rowData = getDtRowData(dt, target);
		assessmentDelete(rowData);
	});

	const assessmentDelete = (assess) => {
		const date = assess.surveyresp_date_nice;

		const title = {
			[ASSESSMENT_INHERENT]: 'Inherent Risk assessment',
			[ASSESSMENT_DILIGENCE]: 'Due Diligence assessment',
			[ASSESSMENT_REVIEW]: 'Periodic Review assessment',
			[ASSESSMENT_VENDORVALUE]: 'Vendor Value assessment',
			[ASSESSMENT_CONCHECKLIST]: 'Contract Checklist assessment',
		}[+assess.surveyresp_type] || 'assessment';

		$('#delete_assessment').find('.modal-body').html(`Are you sure you wish to delete this ${title} from ${date}?`);
		$('#delete_assessment').modal('show');

		$('#delete_assessment_confirm').off('click').on('click', async () => {
			try {
				const postData = {
					type: 'response_delete',
					data: {
						guid: assess.surveyresp_guid,
					},
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Assessment', 'Assessment deleted.', 'success');
				dt.ajax.reload();
				if ($('#vendor_tasks_html').length) loadTaskDescs();
				$('#delete_assessment_confirm').off('click');
				$('#delete_assessment').modal('hide');
			} catch (error) {
				displayNotification('Assessment', 'There was an error deleting the assessment.', 'danger');
				logerror('assessment delete', error);
			}
		});
	};

	return dt;
};

export const loadVendorEdit = ({vend_id, assessType}: {vend_id: number, assessType?: number}) => {
	const start = {
		[ASSESSMENT_INHERENT]: 'inherent',
		[ASSESSMENT_DILIGENCE]: 'residual',
		[ASSESSMENT_REVIEW]: 'review',
		[ASSESSMENT_VENDORVALUE]: 'vendvalue',
		[ASSESSMENT_ONBOARDING]: 'onboarding',
		[ASSESSMENT_OFFBOARDING]: 'offboarding',
	}[assessType] || null;
	post('/ui/vendor_edit', { id: vend_id, start });
};

export const contractsTableBuild = ($cont: $, vend_id, fields) => {
	const allColumns: (DataTables.ColumnSettings & {field?: string, header?: string})[] = [
		{
			title: 'Title',
			data: 'contract_title',
		},
		{
			title: 'Category',
			data: 'service_name',
			searchable: false,
			field: 'category',
		},
		{
			title: 'Type',
			data: 'contract_type',
			searchable: false,
			field: 'contract_type',
		},
		{
			title: 'Status',
			data: 'contract_status_nice',
			searchable: false,
			field: 'status',
		},
		{
			title: 'Stage',
			data: 'contract_stage_nice',
			searchable: false,
			field: 'stage',
		},
		{
			title: 'Importance',
			data: 'weight_title',
			searchable: false,
			field: 'importance',
		},
		{
			title: 'SLA Latest',
			data: 'sla_rating_title',
			searchable: false,
			field: 'sla_latest',
		},
		{
			title: 'KPI Latest',
			data: 'kpi_rating_title',
			searchable: false,
			field: 'kpi_latest',
		},
		{
			title: 'SLA Aggregate',
			data: 'sla_aggregate_title',
			searchable: false,
			field: 'sla_aggregate',
		},
		{
			title: 'KPI Aggregate',
			data: 'kpi_aggregate_title',
			searchable: false,
			field: 'kpi_aggregate',
		},
		{
			title: 'Checklist Status',
			header: 'Checklist',
			data: 'checklist',
			searchable: false,
			field: 'checklist',
		},
		{
			title: 'Effective Date',
			header: 'Effective',
			data: 'contract_startdate_nice',
			searchable: false,
			field: 'startdate',
		},
		{
			title: 'Termination Date',
			header: 'Termination',
			data: 'contract_enddate_nice',
			searchable: false,
			field: 'enddate',
		},
		{
			title: 'Autorenew',
			data: 'contract_autorenew',
			searchable: false,
			field: 'autorenew',
		},
		{
			title: 'Cancel/Renew',
			data: 'contract_deadline_nice',
			searchable: false,
			field: 'deadline',
		},
		{
			title: 'Actions',
			data: 'actions',
			orderable: false,
			searchable: false,
			className: 'pt-col-divider-start text-right',
			render: null,
		},
	];

	const columns = allColumns.filter((col) => !col.field || (fields && fields[col.field]));
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/contracts_load',
			data: (postData) => ({
				...postData,
				vendorid: vend_id,
				archived: $('#contract_search_archived').is(':checked') ? 1 : 0,
				fields,
			}),
		},
		columns,
		columnDefs: [
			{ targets: '_all', render: (val) => htmlEsc(val)},
		],
		drawCallback: () => triggerWindowResize(),
		fixedColumns: {
			leftColumns: 0,
			rightColumns: 1,
		},
		initComplete: () => {
			const $scroll = $cont.find('.dataTables_scroll');
			$scroll.find('.dataTables_scrollBody').doubleScroll({ $insertBefore: $scroll });
		},
		order: [[0, 'desc']],
		responsive: false,
		scrollX: true,
	};

	const tableEl = (
		<table className="table table-striped form-group-sm" width="100%">
			<thead>
				<tr>
					{columns.map((col) => <th>{col.header || col.title}</th>)}
				</tr>
			</thead>
			<tbody></tbody>
		</table>
	);
	const dt = $cont
		.html(tableEl)
		.find('table')
		.off('click')
		.on('click', '.contract_scoring', ({ target }) => {
			const rowData = getDtRowData(dt, target);
			post('/ui/scoring', {
				vend_id,
				contract_guid: rowData.contract_guid,
			});
		})
		.on('click', '.contract_edit', ({ target }) => {
			const rowData = getDtRowData(dt, target);
			post('/ui/contract', {
				vend_id,
				contract_guid: rowData.contract_guid,
			});
		})
		.DataTable(dtOptions);

	return dt;
};

export function contactsTableBuild({$target, vend_id, isEditable}: {$target: $, vend_id: number, isEditable: boolean}) {
	const dtOptions: DataTables.Settings = {
		ajax: {
			url: '/data/contacts_load',
			type: 'POST',
			data: (postData) => ({
				...postData,
				vendorid: vend_id,
				mode: isEditable ? 1 : 0,
			}),
		},
		columns: [
			{
				title: 'Name',
				data: 'vcontact_name',
			},
			{
				title: 'Title',
				data: 'vcontact_title',
			},
			{
				title: 'Phone',
				data: 'vcontact_phone',
			},
			{
				title: 'Email',
				data: 'vcontact_email',
			},
		],
		columnDefs: [{ targets: 0, responsivePriority: 1 }],
		order: [[0, 'desc']],
	};

	if (isEditable) {
		dtOptions.columns.push({
			title: 'Actions',
			data: 'buttons',
			orderable: false,
			searchable: false,
			width: '3em',
		});
		dtOptions.columnDefs.push({ targets: -1, responsivePriority: 2, render: null });
	}
	dtOptions.columnDefs.push({ targets: '_all', render: (val) => htmlEsc(val) });

	const dt = $target.DataTable(dtOptions);

	if (isEditable) {
		$target.on('click', '.contact_edit', ({ target }) => {
			const data = getDtRowData(dt, target);
			$('#vendor_contact_id').val(data.vcontact_id);
			$('#vendor_contact_name').val(data.vcontact_name);
			$('#vendor_contact_title').val(data.vcontact_title);
			$('#vendor_contact_phone').val(data.vcontact_phone);
			$('#vendor_contact_email').val(data.vcontact_email);

			$('#vendor_contact_address1').val(data.vcontact_address1);
			$('#vendor_contact_address2').val(data.vcontact_address2);
			$('#vendor_contact_city').val(data.vcontact_city);
			$('#vendor_contact_state').val(data.vcontact_state);
			$('#vendor_contact_zip').val(data.vcontact_zip);
			$('#vendor_contact_country').val(data.vcontact_country);
			$('#vendor_contact_edit_container').collapse('show');

			$('#vendor_contact_delete').show().off('click').on('click', async (event) => {
				event.preventDefault();

				$('#vendor_contact_edit_container :input').val('');
				$('#vendor_contact_id').val(0);

				try {
					const postData = {
						type: 'vendor_contact_delete',
						data: {
							vendid: vend_id,
							id: data.vcontact_id,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Contact Delete', 'Vendor contact deleted.', 'success');
					$('#vendor_contact_edit_container').collapse('hide');
					dt.ajax.reload();
				} catch (error) {
					displayNotification('Contact Delete', 'There was an error deleting the contact.', 'danger');
					logerror('vendor contact delete', error);
				}
			});
		});
	}

	return dt;
};

if ($('#vendor_summary_print').length == 1) {
	$('#vendor_summary_print').off('click');
	$('#vendor_summary_print').on('click', function () {
		window.print();
	});
}

if ($('#scoring_form_container').length == 1) {
	init_scoring();
}

function init_scoring() {
	// Contract Scoring
	$('#task_add_modal').on('show.bs.modal', () => {
		$('#task_add_modal').find('#task_add_vendor').val([$('#scoring_form_tovendor').data('vendid')]).trigger('chosen:updated');
	});

	$('#scoring_form_tovendor').off('click');
	$('#scoring_form_tovendor').on('click', function (e) {
		post('/ui/vendor_edit', { id: $(this).data('vendid'), start: 'contracts' });
	});

	$('#scoring_form_tocontract').off('click');
	$('#scoring_form_tocontract').on('click', function (e) {
		post('/ui/contract', {
			vend_id: $(this).data('vendid'),
			contract_guid: $(this).data('contractguid'),
		});
	});

	setCollapse($('#scoring_form_sla_open'), $('#scoring_form_sla_container'));
	setCollapse($('#scoring_form_kpi_open'), $('#scoring_form_kpi_container'));

	if ($('#scoring_form').length == 1) {
		const answerTableBuild = ($table: $, contract_guid, question_guid) => {
			const dtOptions: DataTables.Settings = {
				ajax: {
					url: '/data/contract_answers_load',
					type: 'POST',
					data: (postData) =>  ({
						...postData,
						contract_guid,
						question_guid,
					}),
				},
				columns: [
					{
						title: 'Reviewer',
						data: 'usr_name',
					},
					{
						title: 'Response',
						data: 'cona_text',
					},
					{
						title: 'Value',
						data: 'cona_value',
					},
					{
						title: 'Comment',
						data: 'cona_comment',
					},
					{
						title: 'Date',
						data: 'cona_date_nice',
					},
					{
						title: 'Actions',
						data: 'buttons',
						orderable: false,
						searchable: false,
						width: '3em',
					},
				],
				language: {
					emptyTable: ' ',
				},
				order: [[3, 'desc']],
			};

			const dt = $table
				.on('init.dt, draw.dt', () => {
					const showActions = dt.ajax.json().showactions;
					dt.column(4).visible(showActions);
					$table.find('.score_edit').off('click');
					$table.find('.score_delete').off('click');
					if (showActions) {
						$table
							.find('.score_edit')
							.on('click', ({target}) => {
								const data = {
									...getDtRowData(dt, target),
									question_guid,
								};
								editscore($(target).closest('.question.row'), data);
							});
						$table
							.find('.score_delete')
							.on('click', ({target}) => {
								const data = {
									...getDtRowData(dt, target),
									question_guid,
								};
								deletescore($(target).closest('.question.row'), data);
							});
					}
				})
				.DataTable(dtOptions);

			return dt;
		}

		const contract = JSON.parse(atob($('#scoring_form').data('info')));

		// Answers Get
		var quids = [];
		$('#scoring_form div.question.row').each(function () {
			quids.push($(this).data('questionid'));
		});

		let scoring_answer_dt: {quid: string, dt: DataTables.Api}[] = [];

		if (contract.guid != '' && contract.guid != null) {
			for (var i = 0; i < quids.length; i++) {
				var target = $("#scoring_form .question[data-questionid='" + quids[i] + "'] table");
				var dt = answerTableBuild(target, contract.guid, quids[i]);
				scoring_answer_dt.push({
					quid: quids[i],
					dt: dt,
				});
			}
		}
		logme(scoring_answer_dt);

		$(document).on('scroll', function () {
			if ($(document).scrollTop() > 160) {
				$('#scroll-top').show();
			} else {
				$('#scroll-top').hide();
			}
		});

		$('#scroll-top-btn').off('click');
		$('#scroll-top-btn').on('click', function () {
			$(window).scrollTop(0);
		});

		$('.contract_score_save').off('click');
		$('.contract_score_save').on('click', function (e) {
			submit($(this), e);
		});

		const form_prep_cancel = (row) => {
			// show the cancel button for edit
			row.find('.scoring_form_answer_label').html('Edit Response');
			row.find('.contract_score_save').html('Update');
			row.find('.contract_score_cancel').show();
			row.find('.contract_score_cancel').off('click');
			row.find('.contract_score_cancel').on('click', function () {
				var row = $(this).closest('.question.row');
				clearform(row);
			});
		};

		const deletescore = (row, data) => {
			var date = data.cona_date;
			$('#delete_answer')
				.find('.modal-body')
				.html('Are you sure you wish to delete this answer from ' + date + '?');
			$('#delete_answer').modal();

			$('#delete_answer_confirm').off('click').on('click', async () => {
				try {
					const postData = {
						type: 'contract_question_score_delete',
						data: {
							contract_guid: contract.guid,
							question_guid: data.question_guid,
							answer_guid: data.cona_guid,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Scoring', 'Score deleted.', 'success');
					// REDRAW DT
					for (let i = 0; i < scoring_answer_dt.length; i++) {
						if (scoring_answer_dt[i].quid == data.question_guid) {
							scoring_answer_dt[i].dt.ajax.reload();
							break;
						}
					}
					$('#delete_answer_confirm').off('click');
					$('#delete_answer').modal('hide');

					clearform(row);
				} catch (error) {
					displayNotification('Scoring', 'There was an error deleting the score.', 'danger');
					logerror('score delete', error);
				}
			});
		};

		const editscore = (row, data) => {
			// push values to form
			row.find('.scoring_form_answer_guid').val(data.cona_guid);
			row.find('.scoring_form_answer_value').val(data.cona_ansrat_id);
			row.find('.scoring_form_answer_comment').val(data.cona_comment);
			row.find('.scoring_form_answer_date').val(data.cona_date);
			form_prep_cancel(row);
		};

		const clearform = (row) => {
			row.find('.scoring_form_answer_guid').val('');
			row.find('.scoring_form_answer_value').val('');
			row.find('.scoring_form_answer_comment').val('');
			var date = row.find('.scoring_form_answer_date');
			date.val(date.data('defaultvalue'));
			row.find('.scoring_form_answer_label').html('Add Response');
			row.find('.contract_score_save').html('Save');
			row.find('.contract_score_cancel').off('click');
			row.find('.contract_score_cancel').hide();
		};

		const submit = async ($btn: $, event: JQuery.ClickEvent<HTMLElement>) => {
			event.preventDefault();
			const $row = $btn.closest('.row');

			const data = {
				contract_guid: contract.guid,
				question_guid: $row.data('questionid'),
				answer_guid: $row.find('.scoring_form_answer_guid').val(),
				ansrat_id: $row.find('.scoring_form_answer_value').val(),
				text: $row.find('.scoring_form_answer_value').children(':selected').data('title'),
				date: $row.find('.scoring_form_answer_date').val(),
				comment: $row.find('.scoring_form_answer_comment').val(),
			};

			let bad = false;
			if (data.ansrat_id == null || data.ansrat_id == '') {
				displayNotification('Scoring', 'Please set a value.', 'danger');
				bad = true;
			}
			if (data.date == null || data.date == '') {
				displayNotification('Scoring', 'Please set a valid date.', 'danger');
				bad = true;
			}

			if (bad) return;

			$btn.prop('disabled', true);
			$btn.parent().find('.scoring_form_spinner').show();

			try {
				const postData = {
					type: 'contract_question_score',
					data,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification('Scoring', 'Score added.', 'success');
				// REDRAW DT
				for (let i = 0; i < scoring_answer_dt.length; i++) {
					if (scoring_answer_dt[i].quid == data.question_guid) {
						scoring_answer_dt[i].dt.ajax.reload();
						break;
					}
				}
				clearform($row);
			} catch (error) {
				displayNotification('Scoring', 'There was an error saving the score.', 'danger');
				logerror('score submit', error);
			}

			$btn.prop('disabled', false);
			$btn.parent().find('.scoring_form_spinner').hide();
		};
	} // scoring_form exists?
}

const initAccount = () => {
	const checkValidPhone = () => {
		const hasValidPhone = !!$('#account_form_phone').val();
		if (hasValidPhone) {
			$('#account_form_mfa_method_sms').prop('disabled', false);
		} else {
			if ($('#account_form_mfa_method').val() == MFA_METHOD_SMS) $('#account_form_mfa_method').val(MFA_METHOD_EMAIL);
			$('#account_form_mfa_method_sms').prop('disabled', true);
		}
	};
	checkValidPhone();
	$('#account_form_phone').off('change').on('change', () => checkValidPhone());

	const hasPass = $('#account_form_oldpassword').length === 1;

	if (hasPass) {
		const checkQrCode = () => {
			if (!$('#account_mfa_app_qr_code_img').length) {
				$('#account_mfa_app_qr_code').append(`<img id="account_mfa_app_qr_code_img" src="/data/account_mfa_app_qr_code?t=${(new Date()).getTime()}" class="position-absolute" style="border-radius: 6px;">`)
			}
		}
	
		$('#account_form_change_mfa_app').off('click').on('click', () => {
			$('#account_form_change_mfa_app').prop('disabled', true);
	
			checkQrCode();
	
			$('#account_mfa_app_qr_code_regenerate').off('click').on('click', async ({target}) => {
				const $btn = $(target);
				$btn.prop('disabled', true);
	
				try {
					$('#account_mfa_app_qr_code_img').remove();
	
					await setTimeoutPromise(10);
	
					const res = await ajaxPromise('/form/submit', {type: 'account_mfa_app_reset'});
					if (res.rc !== 'OK') throw res.rc;
	
					checkQrCode();
				} catch (error) {
					logerror('account submit', error);
					displayNotification('Account', 'An error occurred while sending this request.', 'danger');
				}
	
				$btn.prop('disabled', false);
			});
	
			$('#account_mfa_app_modal').modal('show');
			$('#account_form_change_mfa_app').prop('disabled', false);
	
			$('#account_form_mfa_method_app').prop('disabled', false); //mfa secret now exists, allow auth app as preferred method
		});

		zxcvbnProgressBar($('#account_form_password_meter'), {
			passwordInput: '#account_form_newpassword',
			scoreInput: '#account_form_password_score',
			warningTarget: '#account_form_password_warning',
			suggestionsTarget: '#account_form_password_suggestions',
		});
	
		$('#account_form_newpassword, #account_form_repeatpassword').on('blur', async ({target}) => {
			await setTimeoutPromise(10);
			if ((target.id == 'account_form_newpassword' && !$('#account_form_repeatpassword').is(':focus')) || target.id == 'account_form_repeatpassword') {
				if ($('#account_form_newpassword').val() != '' && +$('#account_form_password_score').val() < 2) {
					$('#account_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#account_form_password_help').html('The password is not strong enough.');
				} else if ($('#account_form_newpassword').val() != $('#account_form_repeatpassword').val()) {
					$('#account_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#account_form_password_help').html("The passwords you've entered do not match.");
				} else {
					$('#account_form_newpassword').closest('.form-group').find('[class^=col-]').removeClass('has-error');
					$('#account_form_password_help').html('<strong>OPTIONAL:</strong> If you would like to change your password, fill out both the password and confirm fields.');
				}
			}
		});
	}

	$('#account_form_submit').off('click').on('click', async (event) => {
		event.preventDefault();
		let data: any = {
			fname: $('#account_form_fname').val().toString(),
			lname: $('#account_form_lname').val().toString(),
			email: $('#account_form_email').val().toString(),
			phone: $('#account_form_phone').val().toString(),
			address1: $('#account_form_address1').val().toString(),
			address2: $('#account_form_address2').val().toString(),
			city: $('#account_form_city').val().toString(),
			state: $('#account_form_state').val().toString(),
			postalcode: $('#account_form_postalcode').val().toString(),
			country: $('#account_form_country').val().toString(),

		};
		if (hasPass) {
			data = {
				...data,
				oldpass: $('#account_form_oldpassword').val().toString(),
				pass: $('#account_form_newpassword').val().toString(),
				pass2: $('#account_form_repeatpassword').val().toString(),
				mfa: $('#account_form_mfa').length ? ($('#account_form_mfa').is(':checked') ? 1 : 0) : null,
				mfa_method: +$('#account_form_mfa_method').val(),
			};
		}

		const $btn = $(event.target);
		$btn.prop('disabled', true);
		$('#spinner').show();

		try {
			// check empty fields
			$('#account_form').find('.has-error').removeClass('has-error');
			
			if (hasPass && empty(data.oldpass)) {
				$('#account_form_oldpassword').parent().addClass('has-error');
				throw 'BAD_FORM';
			}
	
			if (hasPass && (data.pass != '' || data.pass2 != '')) {
				if (data.pass != '' && +$('#account_form_password_score').val() < 2) {
					$('#account_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#account_form_password_help').html('The new password is not strong enough.');
					throw 'BAD_FORM';

				} else if (data.pass != data.pass2) {
					$('#account_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#account_form_password_help').html("The new passwords you've entered do not match.");
					throw 'BAD_FORM';
				}
			}

			if (empty(data.fname)) {
				$('#account_form_fname').parent().addClass('has-error');
				throw 'BAD_FORM';
			}

			if (empty(data.lname)) {
				$('#account_form_lname').parent().addClass('has-error');
				throw 'BAD_FORM';
			}

			if (empty(data.email) || !validEmail(data.email)) {
				$('#account_form_email').parent().addClass('has-error');
				throw 'BAD_FORM';
			}

			const res = await ajaxPromise('/form/submit', {type: 'account_save', data: data});
			if (res.rc !== 'OK') throw res.rc;
			displayNotification('Account', 'Update successful.', 'success');

			$('#account_form_oldpassword').val('');

			await setTimeoutPromise(250);

			$('#account_form_newpassword').val('').trigger('keyup');
			$('#account_form_repeatpassword').val('');
		} catch (error) {
			switch (error) {
				case 'BAD_FORM':
					displayNotification('Account', 'Please fix any errors and try again.', 'danger');
					break;
				case 'PASS_USED':
					$('#account_form_newpassword').parent().addClass('has-error');
					displayNotification('Account', 'The new password entered has already been used by you.', 'danger');
					break;
				case 'BAD_EMAIL':
					$('#account_form_email').parent().addClass('has-error');
					displayNotification('Account', 'The email address is already in use by another account.', 'danger');
					break;
				case 'BAD_PASS':
					$('#account_form_oldpassword').parent().addClass('has-error');
					displayNotification('Account', 'The password entered was incorrect.', 'danger');
					break;
				default:
					logerror('account submit', error);
					displayNotification('Account', 'An error occurred while sending this request.', 'danger');
					break;
			}
		}

		$('#spinner').hide();
		$btn.prop('disabled', false);
	});
};

if ($('#account_form').length == 1) initAccount();

if ($('#passwordchange_form').length == 1) {
	init_passwordchange();
}

function init_passwordchange() {
	zxcvbnProgressBar($('#passwordchange_form_password_meter'), {
		passwordInput: '#passwordchange_form_newpassword',
		scoreInput: '#passwordchange_form_password_score',
		warningTarget: '#passwordchange_form_password_warning',
		suggestionsTarget: '#passwordchange_form_password_suggestions',
	});

	$('#passwordchange_form_newpassword, #passwordchange_form_repeatpassword').on('blur', function (e) {
		setTimeout(function () {
			if ((e.target.id == 'passwordchange_form_newpassword' && !$('#passwordchange_form_repeatpassword').is(':focus')) || e.target.id == 'passwordchange_form_repeatpassword') {
				if ($('#passwordchange_form_newpassword').val() != '' && +$('#passwordchange_form_password_score').val() < 2) {
					$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#passwordchange_form_password_help').html('The password is not strong enough.');
				} else if ($('#passwordchange_form_newpassword').val() != $('#passwordchange_form_repeatpassword').val()) {
					$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
					$('#passwordchange_form_password_help').html("The passwords you've entered do not match.");
				} else {
					$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').removeClass('has-error');
					$('#passwordchange_form_password_help').html('<strong>REQUIRED:</strong> Your old password has expired. Please fill out both fields with a new password.');
				}
			}
		}, 10);
	});

	$('#passwordchange_form_submit').off('click').on('click', async (event) => {
		event.preventDefault();

		const data = {
			pass: $('#passwordchange_form_newpassword').val().toString(),
			pass2: $('#passwordchange_form_repeatpassword').val().toString(),
		};

		const $btn = $(event.target);

		// check empty fields
		let bad = false;
		$('#passwordchange_form').find('div').removeClass('has-error');

		if (data.pass == '' || data.pass2 == '') {
			bad = true;
			$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
			$('#passwordchange_form_password_help').html('Please enter a new password.');
		}
		if (data.pass != '' && +$('#passwordchange_form_password_score').val() < 2) {
			bad = true;
			$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
			$('#passwordchange_form_password_help').html('The new password is not strong enough.');
		} else if (data.pass != data.pass2) {
			bad = true;
			$('#passwordchange_form_newpassword').closest('.form-group').find('[class^=col-]').addClass('has-error');
			$('#passwordchange_form_password_help').html("The new passwords you've entered do not match.");
		}

		if (bad) return;

		$btn.prop('disabled', true);
		$('#spinner').show();

		try {
			const postData = {
				type: 'passwordchange_save',
				data,
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification('Account', 'Update successful.', 'success');

			setTimeout(() => {
				$('#passwordchange_form_newpassword').val('').trigger('keyup');
				$('#passwordchange_form_repeatpassword').val('');
				setTimeout(() => {
					window.location.href = '/ui';
				}, 250);
			}, 250);
		} catch (error) {
			switch (error.rc) {
				case 'PASS_USED':
					$('#passwordchange_form_newpassword').parent().addClass('has-error');
					displayNotification('Account', 'The new password entered has already been used by you.', 'danger');
					break;
				default:
					displayNotification('Account', 'An error occurred while sending this request.', 'danger');
					logerror('account submit', error);
					break;
			}
		}

		$('#spinner').hide();
		$btn.prop('disabled', false);
	});
}

if ($('#help_search_input').length == 1) {
	init_helpsearch();
}

function init_helpsearch() {
	let help_cats = [];
	$('.help_category').each(function () {
		let topics = [];
		$(this)
			.find('.help_topic')
			.each(function () {
				topics.push($(this).find('td').first().text().trim());
			});
		help_cats.push(topics);
	});
	//logme(help_cats);

	$('#help_search_input').keyup(function (event) {
		let searchTerm = $(this).val().toString();
		searchHelp(searchTerm);
	});

	function searchHelp(s) {
		let is_searching = s.length > 0;
		let searchlist = s.toLowerCase().split(' ');
		let display_any = false;
		let display_count = 0;
		for (let c = 0; c < help_cats.length; c++) {
			let $cat = $('.help_category').eq(c);
			$cat.hide();
			let display_cat = false;
			let topics = help_cats[c];
			for (let i = 0; i < topics.length; i++) {
				let $topic = $cat.find('.help_topic').eq(i);
				$topic.hide();
				for (let j = 0; j < searchlist.length; j++) {
					let result = topics[i].toLowerCase().indexOf(searchlist[j]);
					if (result !== -1) {
						display_any = true;
						display_cat = true;
						display_count++;
						$topic.show();
						break;
					}
				}
			}
			if (display_cat) {
				$cat.show();
			}
		}
		$('#help_search_result').html('').hide();
		if (is_searching) {
			if (display_any) {
				$('#help_search_result')
					.html('Showing ' + display_count + ' results.')
					.show();
			} else {
				$('#help_search_result').html('Showing no results. Clear the search bar above.').show();
			}
		}
	}
}

$('.panel-heading.panel-heading-collapse').on('click', function () {
	$(this).parent().find('.panel-body-collapse').collapse('toggle');
});

$('.panel-body-collapse').on('show.bs.collapse', function () {
	$(this).parent().find('.panel-heading>.panel-heading-openclose').html('<i class="fa fa-caret-up"></i>');
});

$('.panel-body-collapse').on('hide.bs.collapse', function () {
	$(this).parent().find('.panel-heading>.panel-heading-openclose').html('<i class="fa fa-caret-down"></i>');
});

$('.panel-body-collapse').on('shown.bs.collapse', () => {
	const tables = $.fn.dataTable.tables({ visible: true, api: true }) as DataTables.Api;
	tables.columns.adjust().fixedColumns().relayout();
});
