import Highcharts from 'highcharts';

import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/disable-selection';

import { ASSESSMENT_CONCHECKLIST, CONTRACT_STAGE_ACTIVE, CONTRACT_STAGE_CANCELED, CONTRACT_STAGE_EXPIRED, CONTRACT_STAGE_PENDING, CONTRACT_STAGE_RENEWED, CONTRACT_STAGE_TERMINATED, CONTRACT_STATUS_ACTIVE, CONTRACT_STATUS_ARCHIVED, CONTRACT_STATUS_DRAFT, CONTRACT_STATUS_EXPIRED, CONTRACT_STATUS_TERMINATED, CONTRACT_TYPE_STANDARD } from './constants';
import { contractPerformanceTooltipFormatter, printThis, questionSetLoadModal, assessmentsTableBuild, sparkline_defaults } from './main';
import { ajaxPromise, capitalizeFirst, confirmDialog, displayNotification, htmlEsc, logerror, logme, numToPaddedStr, post, setTimeoutPromise } from './utils';

if ($('#contract_form').length == 1) {
	init_contract();
}

function init_contract() {
	// Contract Edit
	let contract = JSON.parse(atob($('#contract_form').data('info')));
	$('#contract_form_type').val(contract.type);

	const isNewContract = !contract.guid;

	const setTitle = (title: string) => {
		$('#contract_form_panel_title').html(htmlEsc(title) + ' <small>Contract Edit</small>');
	};

	function dateOrderCheck() {
		if ($('#contract_form_startdate').val() != '' && $('#contract_form_enddate').val() != '') {
			var dstart = new Date($('#contract_form_startdate').val().toString()),
				dend = new Date($('#contract_form_enddate').val().toString());
			if (dstart >= dend) {
				return false;
			}
		}
		return true;
	}

	const dateTermCheck = (start, cancel, end) => {
		const dnow = new Date();
		const dstart = new Date(start + ' 00:00:00');
		const dend = end ? new Date(end + ' 23:59:59') : null;
		const dcancel = cancel ? new Date(cancel + ' 23:59:59') : null;

		dnow.setHours(0, 0, 0, 0);

		if (dnow < dstart) {
			// INACTIVE
			return 0;
		} else if (dcancel && dstart < dnow && dcancel < dnow && dnow < dend) {
			// CANCEL/RENEW DATE, but before EXPIRE
			return 2;
		} else if (dstart <= dnow && (!dend || dnow < dend)) {
			// ACTIVE
			return 1;
		} else {
			// EXPIRED
			return 3;
		}
	};

	$('#contract_form_startdate, #contract_form_startdate').on('change', function () {
		if (!dateOrderCheck()) {
			displayNotification('Note', 'Effective Date cannot be after Termination Date.', 'warning');
		}
	});

	$('#contract_form_autorenew').on('change', function () {
		if ($('#contract_form_autorenew').val() == '0' || $('#contract_form_autorenew').val().toString().length == 0) {
			$('#contract_form_daystocancel').val(0);
			let end = $('#contract_form_enddate').val();
			$('#contract_form_canceldate').val(end);
		}
	});

	const contractAllowExpire = async () => {
		const confirmed = await confirmDialog({
			dialogTitle: 'Contract Allow Expire',
			bodyText: 'Are you sure you would like to allow this contract to expire?  All termination notifications will be turned off, and the contract will automatically be terminated on the Termination Date.',
			confirmText: 'Allow Expire',
			confirmStyle: 'warning',
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'contract_allow_expire',
				json: true,
				data: JSON.stringify({
					guid: contract.guid,
				}),
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification('Allow Expire Success', 'Termination notifications have been turned off for this contract.', 'success');

			setTimeout(() => post('/ui/contract', {
				vend_id: contract.vendid,
				contract_guid: contract.guid,
			}), 1000);
		}
		catch (error) {
			displayNotification('Allow Expire Error', 'There was an error allowing this contract to expire.', 'danger');
			logerror('contractAllowExpire', error);
		}
	};

	const contractStageChange = async (stage: number) => {
		const {verb, past} = {
			[CONTRACT_STAGE_ACTIVE]: {verb: 'reactivate', past: 'reactivated'},
			[CONTRACT_STAGE_CANCELED]: {verb: 'cancel', past: 'canceled'},
			[CONTRACT_STAGE_RENEWED]: {verb: 'renew', past: 'renewed'},
			[CONTRACT_STAGE_TERMINATED]: {verb: 'terminate', past: 'terminated'},
		}[stage];
		const verbCapitalized = capitalizeFirst(verb);

		const confirmed = await confirmDialog({
			dialogTitle: `Contract ${verbCapitalized}`,
			bodyText: `Are you sure you would like to ${verb} this Contract?`,
			confirmText: 'Yes',
			cancelText: 'No',
			confirmStyle: 'success',
		});
		if (!confirmed) return;

		try {
			const postData = {
				type: 'contract_stagechange',
				data: {
					guid: contract.guid,
					stage,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			displayNotification(`${verbCapitalized} Success`, `The Contract was ${past} successfully.`, 'success');
			await setTimeoutPromise(1000);
			post('/ui/contract', {
				vend_id: contract.vendid,
				contract_guid: contract.guid,
			});
		} catch (error) {
			displayNotification(`${verbCapitalized} Error`, `The Contract cannot be ${past}.`, 'danger');
			logerror('contract stage change', error);
		}
	}

	const setContractStage = (status, stage, type) => {
		const cancelDateForCalculations = type == CONTRACT_TYPE_STANDARD ? $('#contract_form_canceldate').val() : null;
		const timing = dateTermCheck($('#contract_form_startdate').val(), cancelDateForCalculations, $('#contract_form_enddate').val());

		$('#contract_form_reactivate').off('click').hide().text('Reactivate');
		$('#contract_form_renew').off('click').hide();
		$('#contract_form_allow_expire').off('click').hide();
		$('#contract_form_cancel').off('click').hide().text('Cancel Renewal');
		$('#contract_form_terminate').off('click').hide();

		if (stage == CONTRACT_STAGE_RENEWED && status == CONTRACT_STATUS_ACTIVE) {
			$('#contract_form_cancel').show().on('click', (event) => {
				contractStageChange(CONTRACT_STAGE_CANCELED);
				event.preventDefault();
			});
		}
		if (status != CONTRACT_STATUS_ARCHIVED && timing == 1) {
			switch (parseInt(stage)) {
				case CONTRACT_STAGE_ACTIVE:
					if (type == CONTRACT_TYPE_STANDARD) {
						if (contract.enddate) {
							$('#contract_form_renew').show().on('click', (event) => {
								contractStageChange(CONTRACT_STAGE_RENEWED);
								event.preventDefault();
							});
							if (+contract.autorenew > 0) {
								$('#contract_form_cancel').show().on('click', (event) => {
									contractStageChange(CONTRACT_STAGE_CANCELED);
									event.preventDefault();
								});
							} else {
								$('#contract_form_allow_expire').show().on('click', (event) => {
									event.preventDefault();
									contractAllowExpire();
								});
							}
						}

						$('#contract_form_terminate').show().on('click', (event) => {
							contractStageChange(CONTRACT_STAGE_TERMINATED);
							event.preventDefault();
						});
					} else {
						$('#contract_form_terminate').show().on('click', (event) => {
							contractStageChange(CONTRACT_STAGE_TERMINATED);
							event.preventDefault();
						});
						if (contract.enddate || +contract.daystocancel !== 0) {
							$('#contract_form_cancel').show().text('Cancel').on('click', (event) => {
								contractStageChange(CONTRACT_STAGE_CANCELED);
								event.preventDefault();
							});
						}
					}
					break;
				case CONTRACT_STAGE_RENEWED:
					$('#contract_form_type').off('click').prop('disabled', true);
					break;
				case CONTRACT_STAGE_CANCELED:
					$('#contract_form_type').off('click').prop('disabled', true);
					$('#contract_form_reactivate').show().on('click', (event) => {
						contractStageChange(CONTRACT_STAGE_ACTIVE);
						event.preventDefault();
					});
					if (type != CONTRACT_TYPE_STANDARD) {
						$('#contract_form_terminate')
							.show()
							.on('click', (event) => {
								contractStageChange(CONTRACT_STAGE_TERMINATED);
								event.preventDefault();
							});
					}
					break;
				case CONTRACT_STAGE_TERMINATED:
					if (type == CONTRACT_TYPE_STANDARD && status == CONTRACT_STATUS_ACTIVE) {
						$('#contract_form_terminate').show().on('click', (event) => {
							contractStageChange(CONTRACT_STAGE_TERMINATED);
							event.preventDefault();
						});

						if (+contract.allow_expire) $('#contract_form_reactivate').text('Reinstate');
					}

					$('#contract_form_reactivate').show().on('click', (event) => {
						contractStageChange(CONTRACT_STAGE_ACTIVE);
						event.preventDefault();
					});
					break;
			}
		}
	};

	if (isNewContract) {
		$('#contract_form_panel_title').html('Add Contract');
	} else {
		//logme(dateTermCheck(contract.startdate, contract.canceldate, contract.enddate));

		$('.family_contract_link').on('click', function () {
			post('/ui/contract', {
				vend_id: contract.vendid,
				contract_guid: $(this).data('guid'),
			});
		});

		$('#contract_form_title').off('change');
		$('#contract_form_title').on('change', function () {
			setTitle($(this).val().toString().trim());
		});
		setTitle($('#contract_form_title').val().toString().trim());

		$('#contract_form_toscoring').show();

		const setArchive = (status: number) => {
			if (!contract.vendorEditable) {
				$('#contract_form_body :input').prop('disabled', true);
				$('#contract_form_body :input.chosen').trigger('chosen:updated');
				return;
			}
			switch (status) {
				case CONTRACT_STATUS_ACTIVE:
					$('#contract_form_submit').show();
					$('.contract_form_questionset_controls').show();
					$('.contract_form_questionset .handle').show();
					$('.contract_form_question_remove').show();
					$('#contract_form_archive').off('click').hide();
					$('#contract_form_body :input').prop('disabled', false);
					$('#contract_form_body :input.chosen').trigger('chosen:updated');
					$('#contract_form_startdate, #contract_form_enddate, #contract_form_autorenew, #contract_form_daystocancel').prop('disabled', contract.datechange);
					break;
				case CONTRACT_STATUS_TERMINATED:
				case CONTRACT_STATUS_EXPIRED:
					$('#contract_form_submit').hide();
					$('.contract_form_questionset_controls').show();
					$('.contract_form_questionset .handle').show();
					$('.contract_form_question_remove').show();
					$('#contract_form_archive').on('click', () => contractArchive(CONTRACT_STATUS_ARCHIVED));
					$('#contract_form_archive').html('Archive').removeClass('btn-success').addClass('btn-warning').show();
					$('#contract_form_body :input').prop('disabled', true);
					$('#contract_form_body :input.chosen').trigger('chosen:updated');
					$('#contract_form_startdate, #contract_form_enddate, #contract_form_autorenew, #contract_form_daystocancel').prop('disabled', contract.datechange);
					break;
				case CONTRACT_STATUS_ARCHIVED:
					$('#contract_form_submit').hide();
					$('.contract_form_questionset_controls').hide();
					$('.contract_form_questionset .handle').hide();
					$('.contract_form_question_remove').hide();
					$('#contract_form_archive').on('click', () => contractArchive(CONTRACT_STATUS_ACTIVE));
					$('#contract_form_archive').html('Reinstate').removeClass('btn-warning').addClass('btn-success').show();
					$('#contract_form_body :input').prop('disabled', true);
					$('#contract_form_body :input.chosen').trigger('chosen:updated');
					break;
				case CONTRACT_STATUS_DRAFT:
					$('#contract_form_submit').show();
					$('.contract_form_questionset_controls').show();
					$('.contract_form_questionset .handle').show();
					$('.contract_form_question_remove').show();
					$('#contract_form_archive').off('click').hide();
					$('#contract_form_body :input').prop('disabled', false);
					$('#contract_form_body :input.chosen').trigger('chosen:updated');
					$('#contract_form_startdate, #contract_form_enddate, #contract_form_autorenew, #contract_form_daystocancel').prop('disabled', contract.datechange);
					break;
			}
		}

		setArchive(+contract.status);

		setContractStage(contract.status, contract.stage, contract.contract_type);

		const contractArchive = async (status: number) => {
			const language: {[status: number]: {[key: string]: string, style: BootstrapFlavor}} = {
				[CONTRACT_STATUS_ACTIVE]: {title: 'Reinstate', verb: 'reinstate', action: 'reinstated', style: 'success'},
				[CONTRACT_STATUS_ARCHIVED]: {title: 'Archive', verb: 'archive', action: 'archived', style: 'warning'},
			};
			const {title, verb, action, style} = language[status];

			const confirmed = await confirmDialog({
				dialogTitle: `Contract ${title}`,
				bodyText: `Are you sure you would like to ${verb} this Contract?`,
				confirmText: title,
				confirmStyle: style,
			});
			if (!confirmed) return;

			try {
				const postData = {
					type: 'contract_archive',
					data: {
						guid: contract.guid,
						status,
					},
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				displayNotification(`${title} Success`, `The Contract was ${action} successfully.`, 'success');
				await setTimeoutPromise(1000);
				post('/ui/contract', {
					vend_id: contract.vendid,
					contract_guid: contract.guid,
				});
			} catch (error) {
				displayNotification(`${title} Error`, `The Contract cannot be ${action}.`, 'danger');
				logerror('contract archive', error);
			}
		};

		$('.contract_form_question_inuse').popover({
			placement: 'top',
			content: 'Question in use. Cannot be deleted.',
			trigger: 'hover focus',
		});

		const performanceCharts = async () => {
			try {
				const res = await ajaxPromise('/data/performance_get', {data: contract.guid});
				if (res.rc !== 'OK') throw res;
				await setTimeoutPromise(1000);
				let chartElements = '';

				if (res.data && res.data.length) {
					chartElements = (
						<div className="row">
							{res.data.map((charts, index) => (
								<div className="col-sm-6">
									<h3>{index === 0 ? 'SLA' : 'KPI'}</h3>
									{charts.length > 0 ? (
										charts.map((chart) => (
											<div>
												<h4>{chart.text}</h4>
												<div id={`spark_${chart.id}`} className="performance_chart"></div>
												<hr />
											</div>
										))
									) : (
										<p>There is no {index === 0 ? 'SLA' : 'KPI'} performance data to show at this time.</p>
									)}
								</div>
							))}
						</div>
					);
				} else {
					chartElements = <p>There is no performance data to show at this time.</p>;
				}
				$('#tab-performance').empty().append(chartElements);

				if (res.data && res.data.length) {
					for (var i = 0; i < res.data.length; i++) {
						// sections
						for (var j = 0; j < res.data[i].length; j++) {
							// questions
							if (res.data[i][j].data.length > 0) {
								var chartoptions = JSON.parse(JSON.stringify(sparkline_defaults));
								chartoptions.yAxis.categories = res.standardanswers[i];
								switch (i) {
									case 0: // SLA
										chartoptions.tooltip.formatter = function () {
											return contractPerformanceTooltipFormatter(this);
										};
										break;
									case 1: // KPI
										chartoptions.tooltip.formatter = function () {
											return contractPerformanceTooltipFormatter(this);
										};
										break;
								}

								chartoptions.chart.renderTo = 'spark_' + res.data[i][j].id;
								chartoptions.series = [
									{
										data: res.data[i][j].data,
									},
								];

								var test = new Highcharts.Chart('spark_' + res.data[i][j].id, chartoptions);
							} else {
								$('#spark_' + res.data[i][j].id).html('<p class="text-center">No data to display.</p>');
							}
						}
					}
				}
			} catch (error) {
				displayNotification('Load Error', 'There was a problem loading performance data.', 'danger');
				logerror('performance get', error);
			}
		};

		performanceCharts();

		$('#contract_form_print').show();
		$('#contract_form_print').off('click');
		$('#contract_form_print').on('click', function () {
			// Get the Contract title
			let title = $('#contract_form_title').val() + ' - Details';
			let status = $('#contract_status_stage_label').text();
			let config = {
				title: title,
				callback: function () {
					let $body = $('#tab-details').clone();
					$body.find('input').attr('placeholder', 'N/A');
					$body.find('#contract_form_print').parent().html(status); // replace the button set w/ status
					$body.find('.contract_form_questionset_controls').remove();
					$body.append($('#copyright_footer').clone().css('text-align', 'center')); //add the footer
					return $body;
				},
			};
			printThis(config);
			ajaxPromise('/form/submit', { type: 'contract_print', data: { guid: contract.guid } }).catch((error) => {
				logerror('contract print', error);
			});

			return false;
		});
	}

	function daystocancel() {
		let end = $('#contract_form_enddate').val().toString();
		let days = parseInt($('#contract_form_daystocancel').val().toString());
		if (end != '' && end != '0000-00-00' && days != 0 && !isNaN(days)) {
			let d = new Date(end);
			d.setDate(d.getDate() - days + 1);
			$('#contract_form_canceldate').val(d.getFullYear() + '-' + numToPaddedStr(d.getMonth() + 1, 2) + '-' + numToPaddedStr(d.getDate(), 2));
		} else {
			$('#contract_form_canceldate').val(end);
		}
	}

	daystocancel();

	function calc_notificationdate(check) {
		let end = $('#contract_form_canceldate').val().toString();
		let days = parseInt($('#contract_form_notification').val().toString());
		if (end != '' && end != '0000-00-00' && days != 0 && !isNaN(days)) {
			let d = new Date(end);
			d.setDate(d.getDate() - days + 1);
			$('#contract_form_notification_date').val(d.getFullYear() + '-' + numToPaddedStr(d.getMonth() + 1, 2) + '-' + numToPaddedStr(d.getDate(), 2));
			if (check) {
				let today = new Date();
				if (d < today) {
					displayNotification('Warning', 'The Extended Termination Notification Date has already passed.', 'warning');
				}
			}
		} else {
			$('#contract_form_notification_date').val('');
		}
	}

	calc_notificationdate(false);

	$('#contract_form_annual').number(true, 2);
	$('select#contract_form_owners').chosen({ width: '100%' });

	// Probably don't need to pre-remove events
	// I need them to stack below
	//$('#contract_form_enddate, #contract_form_daystocancel').off('change');

	$('#contract_form_enddate, #contract_form_daystocancel').on('change', function () {
		daystocancel();
	});
	$('#contract_form_enddate, #contract_form_daystocancel, #contract_form_notification').on('change', function () {
		calc_notificationdate(true);
	});

	$('#contract_form_reviewexempt').off('change');
	$('#contract_form_reviewexempt').on('change', function () {
		if ($(this).is(':checked')) {
			$('#contract_form_reviewer').prop('disabled', true).val('');
			$('#contract_form_reviewdate').prop('disabled', true).val('');
		} else {
			if (!contract.disabled) {
				$('#contract_form_reviewer').prop('disabled', false);
				$('#contract_form_reviewdate').prop('disabled', false);
			}
		}
	});
	$('#contract_form_reviewexempt').trigger('change');

	let isInitialChangeHandler = true;
	$('#contract_form_type').off('change').on('change', ({ target }) => {
		const type = +$(target).val();
		if (type == CONTRACT_TYPE_STANDARD) {
			$('#contract_form_daystocancel_label').text('Days to Cancel / Renew');
			$('.type_remove').show();
		} else {
			$('#contract_form_daystocancel_label').text('Days to Cancel');
			$('.type_remove').hide();
			if (!isInitialChangeHandler) $('#contract_form_enddate').val('');
		}
		if (!isNewContract) setContractStage(contract.status, contract.stage, type);
		isInitialChangeHandler = false;
	});
	$('#contract_form_type').trigger('change');

	$('#contract_form_toscoring').off('click');
	$('#contract_form_toscoring').on('click', function () {
		post('/ui/scoring', {
			vend_id: contract.vendid,
			contract_guid: contract.guid,
		});
		return false;
	});

	$('.contract_form_questionset').sortable({
		handle: '.handle',
		placeholder: 'ui-state-highlight',
		forcePlaceholderSize: true,
	});

	/** Show memorize button only if there are questions set */
	const checkMemorize = () => {
		$('.contract_form_questionset').each((_, qSetElement) => {
			const $section = $(qSetElement).closest('section');
			$section.find('.contract_form_questionset_save').hide();
			$(qSetElement).children().each((_, qElement) => {
				const questionText = $(qElement).find('textarea.custom_question_text').val().toString().trim();
				if (questionText) {
					$section.find('.contract_form_questionset_save').show();
					return;
				}
			});
		});
	}

	checkMemorize();

	$('.contract_form_sla_question_add').off('click').on('click', ({target}) => {
		const $qSet = $(target).closest('section').find('.contract_form_questionset');
		const $clone = $('#contract_form_sla_question_template>div').clone(true);
		$clone.find('textarea.custom_question_text').on('change', () => checkMemorize());
		$qSet.append($clone).show();
		checkMemorize();
		return false;
	});

	$('.contract_form_kpi_question_add').off('click').on('click', ({target}) => {
		const $qSet = $(target).closest('section').find('.contract_form_questionset');
		const $clone = $('#contract_form_kpi_question_template>div').clone(true);
		$clone.find('textarea.custom_question_text').on('change', () => checkMemorize());
		$qSet.append($clone).show();
		checkMemorize();
		return false;
	});

	var removed = [];

	$('.contract_form_question_remove').off('click');
	$('.contract_form_question_remove').on('click', function () {
		var section = $(this).closest('section'),
			tar = $(this).closest('.contract_form_question_custom');

		if (tar.data('guid') != null) {
			removed.push(tar.data('guid'));
		}
		tar.remove();

		checkMemorize();
		return false;
	});

	$('#contract_form_close').off('click');
	$('#contract_form_close').on('click', function () {
		post('/ui/vendor_edit', { id: contract.vendid, start: 'contracts' });
		return false;
	});

	$('.contract_form_questionset_save').off('click').on('click', ({target}) => {
		const $btn = $(target);
		const type = +$btn.data('qstype'); // SLA or KPI
		const $cont = type == 0 ? $('#contract_form_sla_questions') : $('#contract_form_kpi_questions');

		const questions = $cont.find('.contract_form_question_custom').toArray().map((element) => {
			const $question = $(element);
			return {
				text: $question.find('textarea.custom_question_text').val().toString().trim(),
				importance: 1,
			};
		}).filter((q) => q.text);

		if (!questions.length) return false;

		const list = questions.map((q) => `<li>${htmlEsc(q.text)}</li>`).join('');

		$('#questionset_form_save_questionlist').html(list);
		$('#questionset_form_save_label').val('');

		$('#questionset_form_save_container').modal();

		$('#questionset_form_save_submit').off('click').on('click', async (event) => {
			event.preventDefault();
			try {
				const postData = {
					label: $('#questionset_form_save_label').val().toString().trim(),
					type,
					questions,
				};

				if (!postData.label) {
					displayNotification('Memorize Question Set', 'Please enter a label for the question set.', 'danger');
					return;
				}

				const res = await ajaxPromise('/form/submit', {type: 'contract_questionset_save', data: JSON.stringify(postData), json: 1});
				if (res.rc !== 'OK') throw Error(res);

				displayNotification('Memorize Question Set', 'Question set saved.', 'success');
				$('#questionset_form_save_container').modal('hide');

			} catch (error) {
				logerror('question set submit', error);
				displayNotification('Memorize Question Set', 'There was a problem saving the question set.', 'danger');
			}
		});

		return false;
	});

	$('.contract_form_questionset_load').off('click');
	$('.contract_form_questionset_load').on('click', ({target}) => {
		const type = +$(target).data('qstype');
		const $target = type === 0 ? $('#contract_form_sla_questions') : $('#contract_form_kpi_questions');

		const onQsetSelected = (rowData) => {
			const $section = $target.find('.contract_form_questionset');
			rowData.qs_data.forEach((q) => {
				// clone field input in `tar`, insert each question
				const $clone = type ? $('#contract_form_kpi_question_template>div').clone(true) : $('#contract_form_sla_question_template>div').clone(true);
				$clone.find('.custom_question_text').val(q.text);
				$section.find('p.noquestions').remove();
				$section.append($clone).show();
				$target.find('.contract_form_questionset_save').show();
			});
		}

		questionSetLoadModal({type, onQsetSelected});

		return false;
	});

	// Contract Save
	$('#contract_form').off('submit');
	$('#contract_form').on('submit', async function (event) {
		event.preventDefault();
		var data = JSON.parse(JSON.stringify(contract));

		data.title = $('#contract_form_title').val().toString().trim();
		data.service = $('#contract_form_service').val().toString().trim();
		data.description = $('#contract_form_description').val().toString().trim();
		data.notes = $('#contract_form_notes').val().toString().trim();
		data.contract_type = +$('#contract_form_type').val();
		data.importance = $('#contract_form_importance').val().toString().trim();
		data.refnum = $('#contract_form_refnum').val().toString().trim();
		data.annual = +($('#contract_form_annual').val() * 100);
		data.costcenter = $('#contract_form_costcenter').val();
		data.startdate = $('#contract_form_startdate').val().toString().trim();
		data.enddate = $('#contract_form_enddate').val().toString().trim();
		data.daystocancel = $('#contract_form_daystocancel').val().toString().trim();
		data.canceldate = $('#contract_form_canceldate').val().toString().trim();
		data.autorenew = parseInt($('#contract_form_autorenew').val().toString());
		data.cancelsentdate = $('#contract_form_cancelsentdate').val();
		data.reviewexempt = $('#contract_form_reviewexempt').is(':checked') ? 1 : 0;
		data.reviewer = $('#contract_form_reviewer').val();
		data.reviewdate = $('#contract_form_reviewdate').val();
		data.owners = $('#contract_form_owners').val();
		data.notif_cancel = $('#contract_form_notification').val();
		data.questions = [];
		if (removed.length > 0) {
			data.removed_questions = removed;
		}

		// VALIDATION PLEASE
		let bad = false;

		if (data.contract_type == CONTRACT_TYPE_STANDARD && !data.enddate) {
			displayNotification('Termination Date', 'Standard contracts must have a Termination Date.', 'danger');
			bad = true;
		}

		if (data.title == null || data.title == '' || !data.startdate || data.importance == null || data.importance == '') {
			displayNotification('Form Incomplete', 'Please fill out all required fields.', 'danger');
			bad = true;
		}

		if (!dateOrderCheck()) {
			displayNotification('Date Error', 'Effective Date cannot be after Termination Date.', 'danger');
			bad = true;
		}

		if (data.daystocancel == '') {
			// Default cancel days to zero
			$('#contract_form_daystocancel').val(0);
			data.daystocancel = 0;
			daystocancel();
			calc_notificationdate(false);
		} else {
			// daystocancel being set implies a date other than the expiration date, check to make sure it's not before start date
			const s = new Date(data.startdate);
			const c = new Date(data.canceldate);
			if (data.contract_type == CONTRACT_TYPE_STANDARD && c <= s) {
				displayNotification('Cancel Date', 'Cancel Date cannot be before the Start Date.', 'danger');
				bad = true;
			}
		}

		if (data.notif_cancel == '') {
			// Default cancel days to zero
			$('#contract_form_notification').val(0);
			data.notif_cancel = 0;
			daystocancel();
			calc_notificationdate(false);
		} else {
			// daystocancel being set implies a date other than the expiration date, check to make sure it's not before start date
			const s = new Date(data.startdate);
			const c = new Date($('#contract_form_notification_date').val().toString());
			if (c && c <= s) {
				displayNotification('Extended Termination Notification', 'Extended Termination Notification Date cannot be before the Start Date.', 'danger');
				bad = true;
			}
		}

		if (data.reviewexempt == 0 && (data.reviewer.length == 0 || data.reviewdate.length == 0)) {
			displayNotification('Not Review Exempt', 'This contract is not review-exempt, therefore a reviewer & review date should be defined.', 'danger');
			bad = true;
		}

		if (data.cancelsentdate == '') {
			data.cancelsentdate = null;
		}

		$('#contract_form_sla_questions .contract_form_question_custom').each((_, element) => {
			const questionText = $(element).find('textarea.custom_question_text').val().toString().trim();
			if (questionText === '') {
				displayNotification('SLAs', 'Please fill out all SLAs defined.', 'danger');
				bad = true;
			}
			data.questions.push({
				type: 0,
				text: questionText,
				guid: $(element).data('guid') ? $(element).data('guid') : null,
			});
		});
		$('#contract_form_kpi_questions .contract_form_question_custom').each((_, element) => {
			const questionText = $(element).find('textarea.custom_question_text').val().toString().trim();
			if (questionText === '') {
				displayNotification('KPIs', 'Please fill out all KPIs defined.', 'danger');
				bad = true;
			}
			data.questions.push({
				type: 1,
				text: questionText,
				category: $(element).find('select.custom_question_category').val(),
				importance: 1, //$(this).find('select.custom_question_importance').val(),
				guid: $(element).data('guid') ? $(element).data('guid') : null,
			});
		});
		//logme(data.questions);

		if (bad) return;

		const cancelDateForCalculations = data.contract_type == CONTRACT_TYPE_STANDARD ? data.canceldate : null;
		const timing = dateTermCheck(data.startdate, cancelDateForCalculations, data.enddate);

		logme('contract canceldate: [' + data.canceldate + ']');
		logme('contract timing: [' + timing + ']');
		logme('contract stage: [' + data.stage + ']');
		logme('contract autorenew: [' + data.autorenew + ']');

		data.stage = parseInt(data.stage);
		if (data.stage != CONTRACT_STAGE_PENDING && timing == 0) {
			const confirmed = await confirmDialog({
				dialogTitle: 'Contract Pending',
				bodyText: 'This contract has not yet started, and will be set to pending.',
				confirmText: 'OK',
				confirmStyle: 'info',
			});
			if (!confirmed) return;
		} else if ([CONTRACT_STAGE_PENDING, CONTRACT_STAGE_ACTIVE].indexOf(data.stage) != -1 && data.autorenew > 0 && timing == 2) {
			const confirmed = await confirmDialog({
				dialogTitle: 'Contract Renewal',
				bodyText: 'This contract cancel date has already passed, and will be renewed automatically.',
				confirmText: 'OK',
				confirmStyle: 'info',
			});
			if (!confirmed) return;
		} else if (data.stage != CONTRACT_STAGE_EXPIRED && timing == 3) {
			const confirmed = await confirmDialog({
				dialogTitle: 'Contract Expired',
				bodyText: 'This contract term has already passed, and will be set to expired.',
				confirmText: 'OK',
				confirmStyle: 'info',
			});
			if (!confirmed) return;
		}

		contractSave(data);
	});

	const contractSave = async (data) => {
		$('#contract_form').prop('disabled', true);
		$('#contract_form_submit').prop('disabled', true);
		$('#contract_form_spinner').show();

		try {
			const postData = {
				type: 'contract_save',
				data,
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			contract.guid = res.guid;

			$('#contract_form_panel_title').html('Edit Contract');
			displayNotification('Contract Save', 'Contract saved.', 'success');

			post('/ui/contract', {
				vend_id: contract.vendid,
				contract_guid: contract.guid,
			});
		} catch (error) {
			displayNotification('Contract Save', 'There was an error saving this contract.', 'danger');
			logerror('contract submit', error);
		}

		$('#contract_form_spinner').hide();
		$('#contract_form').prop('disabled', false);
		$('#contract_form_submit').prop('disabled', false);
	};

	if ($('#contract_checklist_add').length) {
		$('#contract_checklist_add').off('click').on('click', () => $('#checklist_create_modal').modal('show'));

		$('#checklist_create_form').off('submit').on('submit', async (event) => {
			event.preventDefault();
			const $btn = $(event.target).closest('btn');
			$btn.prop('disabled', true);
			try {
				const postData = {
					type: 'response_checklist_create',
					data: JSON.stringify({
						vend_id: +contract.vendid,
						type: ASSESSMENT_CONCHECKLIST,
						cset_id: +$('#checklist_create_set').val(),
						con_guid: contract.guid,
				}),
					json: true,
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				post('/ui/assessment', {
					surv_guid: res.guid,
					type: ASSESSMENT_CONCHECKLIST,
					vend_id: +contract.vendid,
				});
			} catch (error) {
				switch (error.rc) {
					case 'NO_MASTER':
						displayNotification('Contract Checklist Create', 'No Contract Checklist template loaded.  Please contact the Vendor Management Office.', 'danger', 5000);
						break;
					case 'NO_CHECKLIST_SET':
						displayNotification('Contract Checklist Create', 'No Contract Checklist Set selected for this contract.  Please save a checklist set for the contract and try again.', 'danger', 5000);
						break;
					default:
						displayNotification('Contract Checklist Create', 'The Contract Checklist could not be created for this vendor.  Please contact the Vendor Management Office.', 'danger', 5000);
						logerror('response contract checklist create', error);
				}
			}

			$btn.prop('disabled', false);
		});

		$('#checklist_create_modal').on('hidden.bs.modal', () => $('#checklist_create_form').trigger('reset'));

		let checklistDt: DataTables.Api = null;

		const loadChecklistTable = () => {
			if (checklistDt == null) checklistDt = assessmentsTableBuild({$table: $('#contract_checklist_table'), vend_id: +contract.vendid, type: ASSESSMENT_CONCHECKLIST, con_guid: contract.guid});
			else checklistDt.ajax.reload();
		};

		if ($('#tab-checklist').hasClass('active')) {
			loadChecklistTable();
		}
		$('a[href="#tab-checklist"]').on('show.bs.tab',  () => loadChecklistTable());
	}
}
