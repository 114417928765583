import { ASSESSMENT_CONCHECKLIST, ASSESSMENT_VENDORVALUE, SURVEY_COMPLETE, SURVEY_OPEN } from './constants';
import { historyTable } from './history';
import { loadVendorEdit } from './main';
import { ajaxPromise, confirmDialog, displayNotification, htmlEsc, logerror, post } from './utils';

const initResponse = ($main: $) => {
	const saveProgress = async ({ taskSubmit = false } = {}) => {
		let data = JSON.parse(JSON.stringify(response));

		//empty og answers if present.
		data.answers = [];

		let bad = [];

		$main.find('#response_form [data-questionid]').find('select').parent().removeClass('has-error');

		$main.find('#response_form [data-questionid]').each((_, element) => {
			const $question = $(element);
			if (parseInt($question.data('hidden')) === 1) return;

			const value = $question.find('select.answer_value').val() != null && $question.find('select.answer_value').val() != '' ? $question.find('select.answer_value').val() : '';
			const importance = $question.find('select.answer_importance').val() != null && $question.find('select.answer_importance').val() != '' ? parseFloat($question.find('select.answer_importance').val().toString()) : '';

			if (value == '' && importance == '') return;

			const ans: Record<string, any> = {
				guid: $question.data('questionid'),
				value,
				text: $question.find('select.answer_value > :selected').length == 1 ? $question.find('select.answer_value > :selected').text() : '',
				comment: $question.find('textarea.answer_comment').length == 1 ? $question.find('textarea.answer_comment').val() : '', //Not required
			};

			// Include Importance if in Vendor Value
			if (parseInt(data.type) == ASSESSMENT_VENDORVALUE) {
				ans.importance = importance;
			}

			// Check the other required fields, assuming value is set
			if (ans.guid == '' || ans.text == '' || ans.value == '' || (parseInt(data.type) == ASSESSMENT_VENDORVALUE && ans.text != 'N/A' && ans.importance == '')) {
				bad.push($question);
				$question.find('select').parent().addClass('has-error');
				return;
			}

			// All set!
			data.answers.push(ans);
		});

		if (!bad.length && !data.answers.length) {
			//No answers (good or bad)
			displayNotification('Assessment', 'Please fill out an answer.', 'danger');
			return;
		}

		if (bad.length) {
			displayNotification('Assessment', 'Please fill out each response defined.', 'danger');
			return;
		}

		data.reviewedby = $main.find('#response_form_reviewedby').val() || response.reviewedby;
		data.date = $main.find('#response_form_date').val();
		data.complete = checkComplete() ? 1 : 0;
		data.task_submit = taskSubmit ? 1 : 0;

		if (isExternal && taskSubmit) {
			const confirmed = await confirmDialog({
				dialogTitle: 'Assessment Submit',
				bodyText: 'Are you sure you would like to save and submit this assessment?  The assessment will be closed.',
				confirmText: 'Submit',
				confirmStyle: 'success',
				cancelText: 'Cancel',
			});
			if (!confirmed) return;
		}

		// Submit the form
		$main.find('#response_form_submit').prop('disabled', true);
		$main.find('#response_form_spinner').show();

		try {
			const postData = {
				type: 'response_save',
				data,
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;

			response.resp_guid = res.guid;
			dataCheckDirty(false);
			if (historyTable) historyTable.ajax.reload();
			displayNotification('Assessment', 'Assessment progress saved.', 'success');
			if (isExternal && taskSubmit) window.location.reload();
			if (isExternal && !taskSubmit) {
				await confirmDialog({
					dialogTitle: 'Assessment Saved',
					bodyText: 'Your progress has been saved.  You can continue editing and submit the assessment when you have finished, or close this window if you have finished but would like to leave the assessment open for others.',
					confirmText: 'Ok',
					confirmStyle: 'primary',
					showCancelButton: false,
				});
			}
		} catch (error) {
			displayNotification('Assessment', 'There was an error saving progress.', 'danger');
			logerror('response submit', error);
		}

		$main.find('#response_form_spinner').hide();
		$main.find('#response_form_submit').prop('disabled', false);
	};

	const checkComplete = () => {
		const incompleteQuestion = $main
			.find('#response_form [data-questionid]')
			.toArray()
			.find((element: HTMLElement) => {
				const $question = $(element);
				if (+$question.data('hidden')) return false; // Conditional/hidden questions don't count

				const $value = $question.find('select.answer_value');
				const value = $value.val() != null ? $value.val() : '';
				const $importance = $question.find('select.answer_importance');
				const importance = $importance.val() != null ? parseFloat($importance.val().toString()) : '';

				if (value == '' && importance == '') return true; //Incomplete question found

				const ans = {
					guid: $question.data('questionid'),
					value,
					text: $value.children(':selected').length == 1 ? $value.children(':selected').text() : '',
					importance,
				};

				// Check the other required fields, assuming value is set
				return ans.guid == '' || ans.text == '' || ans.value == '' || (type == ASSESSMENT_VENDORVALUE && ans.text != 'N/A' && ans.importance == '');
			});

		return !incompleteQuestion;
	};

	const lock = ({target}: JQuery.ClickEvent<HTMLElement>) => {
		if (!checkComplete()) {
			displayNotification('Assessment', 'Cannot lock, all available questions must be answered first.', 'danger');
			return;
		}

		locking($(target), SURVEY_COMPLETE);
	};

	const dataCheckDirty = (isDirty: boolean) => {
		dirty = isDirty;
		if (isDirty || (isExternal && showSubmit)) {
			$main.find('#response_form_task_submit_external').fadeIn(300);
			$main.find('#response_form_submit').fadeIn(300).off('click').on('click', (event) => {
				event.preventDefault();
				saveProgress();
			});
			$main.find('#response_form_cancel').text(`Cancel & ${response.return_to_text}`);

			$main.find('#response_form_lock').hide().off('click');
		} else {
			// is clean to lock
			if (checkComplete()) {
				$main.find('#response_form_lock').fadeIn(300).off('click').on('click', lock);
			}
			$main.find('#response_form_cancel').text(response.return_to_text);

			$main.find('#response_form').off('submit');
			if (!isExternal) $main.find('#response_form_submit').hide();
		}
	};

	const closeForm = () => {
		if (dirty) {
			$main.find('#response_form_cancel_modal_confirm').off('click').on('click', () => returnToX());
			$main.find('#response_form_cancel_modal').modal();
			return;
		}

		returnToX();
	};

	const locking = async ($btn: $, status: number) => {
		$btn.prop('disabled', true);

		try {
			const postData = {
				type: 'response_lock',
				data: {
					resp_guid: response.resp_guid,
					status,
					type,
				},
			};
			const res = await ajaxPromise('/form/submit', postData);
			if (res.rc !== 'OK') throw res;
			if (isInline) {
				returnToX();
			} else {
				post('/ui/assessment', {
					surv_guid: response.resp_guid,
					vend_id: response.vend_id,
					cat_id: response.cat_id,
					type,
					review: response.review,
				});
			}
		} catch (error) {
			switch (error.rc) {
				case 'NO_BASIS':
					displayNotification('Assessment', 'All answers cannot be N/A.', 'danger');
					break;
				default:
					displayNotification('Assessment', 'There was an error marking this Assessment complete.', 'danger');
					logerror('response lock', error);
			}
		}

		$btn.prop('disabled', false);
	};

	const returnToX = () => {
		$main.find('#response_form :input').val('');
		if (type == ASSESSMENT_CONCHECKLIST) {
			post('/ui/contract', {
				vend_id: response.vend_id,
				contract_guid: response.con_guid,
				start: 'checklist',
			});
			return;
		}
		loadVendorEdit({vend_id: +response.vend_id, assessType: type});
	};

	const checkQuestionDisplay = () => {
		$main.find('#response_form [data-hidden]').hide().data('hidden', 1);
		$main.find('#response_form select').each((_, select) => {
			const $select = $(select);

			// Show targets that are selected
			if ($select.children('option:selected').data('target')) {
				const ref = $select.children(':selected').data('target').split(',');
				ref.forEach((target) => {
					const $target = $main.find("#response_form [data-questionid='" + target + "']");
					if ($target.length == 1) {
						$target.data('hidden', 0);
						$target.show();
					}
				});
			}
		});
	};

	// Assessment Response
	let dirty = false;

	let response = JSON.parse(atob($main.find('.response_form_container').data('params')));
	const isExternal = response.is_external;
	const isInline = response.is_inline;
	const showSubmit = response.show_submit;
	const type = +response.type;
	delete response.is_external;
	delete response.show_submit;

	response.answers = [];

	if (response.vend_id) {
		$('#task_add_modal').on('show.bs.modal', () => {
			$('#task_add_modal').find('#task_add_vendor').val([response.vend_id]).trigger('chosen:updated');
		});
	}

	$(document).on('scroll', () => {
		if ($(document).scrollTop() > 160) $main.find('#scroll-top').show();
		else $main.find('#scroll-top').hide();
	});

	$main.find('#response_export').off('click').on('click', () => {
		post('/export/response', { surveyresp_guid: response.resp_guid }, '_blank');
	});

	$main.find('#response_print').off('click').on('click', () => {
		post('/printout/assessment', { surveyresp_guid: response.resp_guid }, '_blank');
	});

	$main.find('#scroll-top-btn').off('click').on('click', () => $(window).scrollTop(0));

	$main.find('#response_form .answer_importance_tooltip').popover({
		html: true,
		content: $main.find('#importance_tooltip_content').html(),
		trigger: 'hover focus',
	});

	$main.find('.response_form_question_add').on('click', ({target}) => {
		const $section = $(target).parent();
		const $clone = $main.find('#response_form_question_template > div').clone(true);
		$section.find('p.noquestions').remove();
		$section.append($clone).show();
		return false;
	});

	$main.find('.response_form_question_remove').on('click', ({target}) => {
		$(target).closest('.response_form_question_custom').remove();
		return false;
	});

	if (response.resp_id !== null && response.resp_guid !== null) {
		// In-progress Assessment
		checkQuestionDisplay();

		if (checkComplete()) {
			$main.find('#response_form_lock').show().on('click', lock);
		}

		$main.find('#response_form select.answer_value').chosen({ width: '100%' });
	}

	// Completed Assessment
	$main.find('#response_form .answer').each((_, element) => {
		const $answer = $(element);
		if (!$answer.data('target')) return;
		const ref = JSON.parse(atob($answer.data('target')));
		ref.forEach((target) => {
			const $target = $main.find("#response_form [data-questionid='" + target + "']");
			if ($target.length == 1) {
				$target.data('hidden', 0);
				$target.show();
			}
		});
	});

	$main.find('#response_form_task_submit_external').off('click').on('click', (event) => {
		event.preventDefault();
		saveProgress({ taskSubmit: true });
	});

	$main.find('#response_form :input').on('change', () => {
		checkQuestionDisplay();
		dataCheckDirty(true);
	});

	$main.find('#response_form_reviewedby, #response_form_date').on('change', () => dataCheckDirty(true));

	$main.find('#response_form_question_template :input').on('change', ()  => dataCheckDirty(true));

	if ($main.find('#response_form_unlock').length == 1) {
		$main.find('#response_form_unlock').off('click').on('click', ({target}) => locking($(target), SURVEY_OPEN));
		if ($main.find('#response_form_override_update').length == 1) {
			// Disable override reason if override is empty
			const overrideIsEmpty = $main.find('#response_form_override').val() == '';
			$main.find('#response_form_override_reason').prop('disabled', overrideIsEmpty);

			// Disable override reason if override is empty, also on change
			$main.find('#response_form_override').off('change').on('change', ({target}) => {
				if ($(target).val() == '') {
					$main.find('#response_form_override_reason').val('');
					$main.find('#response_form_override_reason').prop('disabled', true);
				} else {
					$main.find('#response_form_override_reason').prop('disabled', false);
				}
			});

			$main.find('#response_form_override_update').off('click').on('click', async () => {
				const overrideId = $main.find('#response_form_override').val();
				const overrideTitle = $main.find('#response_form_override > option:selected').text();
				const reason = $main.find('#response_form_override_reason').val();

				if (overrideId != '' && reason == '') {
					displayNotification('Override', 'Please provide a reason for the override.', 'danger');
					return;
				}

				try {
					const postData = {
						type: 'response_override',
						data: {
							resp_guid: response.resp_guid,
							override: overrideId,
							reason,
						},
					};
					const res = await ajaxPromise('/form/submit', postData);
					if (res.rc !== 'OK') throw res;

					displayNotification('Override', 'Override set.', 'success');
					const rating = $main.find('#response_rating').data('rating');
					if (overrideId == '' || overrideId == null) {
						$main.find('#response_rating').html(htmlEsc(rating));
					} else {
						$main.find('#response_rating').html(htmlEsc(`${overrideTitle} (scored ${rating})`));
					}
					if (historyTable) historyTable.ajax.reload();
				} catch (error) {
					displayNotification('Override', 'There was an error setting the override.', 'danger');
					logerror('response override submit', error);
				}
			});
		}
	}

	$main.find('#response_form_cancel').off('click').on('click', () => closeForm());

	if ($main.find('#response_form_delete').length) {
		$main.find('#response_form_delete').off('click').on('click', async ({target}) => {
			const confirmed = await confirmDialog({
				dialogTitle: 'Assessment Delete',
				bodyText: 'Are you sure you would like to delete this assessment?',
				confirmText: 'Delete',
				confirmStyle: 'danger',
				cancelText: 'Cancel',
			});
			if (!confirmed) return;

			const $btn = $(target);
			$btn.prop('disabled', true);

			try {
				const postData = {
					type: 'response_delete',
					data: {
						guid: response.resp_guid,
					},
				};
				const res = await ajaxPromise('/form/submit', postData);
				if (res.rc !== 'OK') throw res;

				returnToX();
			} catch (error) {
				displayNotification('Assessment', 'There was an error deleting this assessment.', 'danger');
				logerror('response delete', error);
			}

			$btn.prop('disabled', false);
		});
	}

	if (isExternal && showSubmit) dataCheckDirty(false);
};

if ($('.response_form_outer_container').length) {
	$('.response_form_outer_container').each((_, element) => initResponse($(element)));
}

if ($('#assessment_print_container').length == 1) {
	setTimeout(() => window.print(), 1000);
}
